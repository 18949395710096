import React, { Component } from "react";
import btn_icon_1027699 from "../images/btn_icon_1027699.png";
import btn_icon_341038 from "../images/btn_icon_341038.png";
import ImmutableWeaponRule from "./ImmutableWeaponRule";
import * as SPRCStyles from "../styles/SoundProfileRuleConfig.module.css";
import { SpanName } from "../tracing/SpanNames";
import { withTrace } from "../tracing/tracing";
// UI framework component imports

export default class ImmutableSoundProfileRuleConfig extends Component {
  // Properties used by this component:
  // soundProfileConfig, ruleId, ruleTypeName, soundMapping, itemName, dataSheetRow, visualStateIndex

  constructor(props) {
    super(props);

    this.state = {
      newWeaponName: "",
      picker: "",
      visualStateIndexOverride: 0,
    };
  }

  // --- Functions for component state index 0 (1 of 2) ---

  onClick_state0_elIconButton2 = (ev) => {
    this.setState({ visualStateIndexOverride: 1 });
  };

  renderState0() {
    const style_state0_elCard = {
      width: "100%",
      height: "100%",
    };
    const style_state0_elCard_outer = {
      backgroundColor: "white",
      filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
      overflow: "visible",
    };

    const value_text = this.props.ruleTypeName;

    const style_state0_elText475640 = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
    };

    const style_state0_elIconButton2 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_1027699 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "129.499%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    return (
      <div className={SPRCStyles.SoundProfileRuleConfig}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRCStyles.state0_elCard}`}
            style={style_state0_elCard_outer}
          >
            <div className="cardBg" style={style_state0_elCard} />
          </div>
        </div>

        <div className={`layoutFlow ${SPRCStyles.layoutFlow}`}>
          <div className={SPRCStyles.state0_elText475640}>
            <div className="baseFont" style={style_state0_elText475640}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={SPRCStyles.state0_elIconButton2}>
            <button
              className="actionFont"
              style={style_state0_elIconButton2}
              onClick={this.onClick_state0_elIconButton2}
            />
          </div>
        </div>
      </div>
    );
  }

  // --- Functions for component state index 1 (2 of 2) ---

  onClick_state1_elIconButton2 = (ev) => {
    this.setState({ visualStateIndexOverride: 0 });
  };

  onClick_state1_elDeleteRuleFunctionDeclaration = (ev) => {
    // Write your script here
    const itemName = ev;
    if (!this.props.ruleId || !this.props.soundProfileConfig) {
      return;
    }

    const config =
      this.state.soundProfileConfig || this.props.soundProfileConfig;
    var updatedConfig = { ...config };
    delete updatedConfig[this.props.ruleId][itemName];

    this.setState({ soundProfileConfig: updatedConfig });
  };

  renderState1() {
    const style_state1_elCard = {
      width: "100%",
      height: "100%",
    };
    const style_state1_elCard_outer = {
      backgroundColor: "white",
      filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
      overflow: "visible",
    };
    const style_state1_elGrayBackground = {
      width: "100%",
      height: "100%",
    };
    const style_state1_elGrayBackground_outer = {
      backgroundColor: "#ececec",
    };

    const value_text = this.props.ruleTypeName;

    const style_state1_elText475640 = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
    };

    const style_state1_elIconButton2 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_341038 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "150.614%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    const config =
      this.state.soundProfileConfig || this.props.soundProfileConfig;
    const items_list = Object.entries(config[this.props.ruleId]).sort((a, b) =>
      a[0] < b[0] ? -1 : 1
    );
    const style_elList = {
      height: "auto",
    };
    const style_state1_elSoundProfileEditor = {
      pointerEvents: "auto",
    };

    const value_deleteRuleFunctionDeclaration = this.props.ruleId;

    const style_state1_elDeleteRuleFunctionDeclaration = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
      cursor: "pointer",
      pointerEvents: "auto",
    };
    const style_state1_elDeleteRuleFunctionDeclaration_outer = {
      display: "none",
    };

    return (
      <div className={SPRCStyles.SoundProfileRuleConfig}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRCStyles.state1_elCard}`}
            style={style_state1_elCard_outer}
          >
            <div className="cardBg" style={style_state1_elCard} />
          </div>

          <div
            className={SPRCStyles.state1_elGrayBackground}
            style={style_state1_elGrayBackground_outer}
          >
            <div style={style_state1_elGrayBackground} />
          </div>
        </div>

        <div className={`layoutFlow ${SPRCStyles.layoutFlow}`}>
          <div className={SPRCStyles.state1_elText475640}>
            <div className="baseFont" style={style_state1_elText475640}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={SPRCStyles.state1_elIconButton2}>
            <button
              className="actionFont"
              style={style_state1_elIconButton2}
              onClick={this.onClick_state1_elIconButton2}
            />
          </div>

          <div className={SPRCStyles.state1_elSoundProfileEditor}>
            <div style={style_state1_elSoundProfileEditor}>
              <div className="hasNestedComps elList">
                <ul style={style_elList}>
                  {items_list.map((row, index) => {
                    const SOUND_DISABLED_STATE = 1;
                    const SOUND_ENABLED_STATE = 0;
                    let initialState =
                      row[1] === "NoSound"
                        ? SOUND_DISABLED_STATE
                        : SOUND_ENABLED_STATE;
                    let ruleProps = {
                      itemName: row[0],
                      soundMapping: row[1],
                      ruleId: this.props.ruleId,
                      visualStateIndex: initialState,
                      soundProfileConfig: config,
                      deleteRuleCallback:
                        this.onClick_state1_elDeleteRuleFunctionDeclaration,
                    };
                    let itemComp = (
                      <ImmutableWeaponRule
                        {...ruleProps}
                        ref={(el) => (this._state1_elComp = el)}
                        appActions={this.props.appActions}
                        deviceInfo={this.props.deviceInfo}
                        locStrings={this.props.locStrings}
                        soundsProvider={this.props.soundsProvider}
                        foldersProvider={this.props.foldersProvider}
                      />
                    );
                    return <li key={row.key}>{itemComp}</li>;
                  })}
                  <div
                    className="marker"
                    ref={(el) => (this._elList_endMarker = el)}
                  />
                </ul>
              </div>
            </div>
          </div>

          <div
            className={SPRCStyles.state1_elDeleteRuleFunctionDeclaration}
            style={style_state1_elDeleteRuleFunctionDeclaration_outer}
          >
            <button
              className="baseFont"
              style={style_state1_elDeleteRuleFunctionDeclaration}
              onClick={this.onClick_state1_elDeleteRuleFunctionDeclaration}
            >
              <div>
                {value_deleteRuleFunctionDeclaration !== undefined ? (
                  value_deleteRuleFunctionDeclaration
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return withTrace(
      SpanName.IMMUTABLE_SOUND_PROFILE_RULE_CONFIG_RENDER,
      () => {
        switch (parseInt(this.state.visualStateIndexOverride, 10)) {
          default:
          case 0:
            return this.renderState0();
          case 1:
            return this.renderState1();
        }
      },
      {
        ruleId: this.props.ruleId,
        ruleTypeName: this.props.ruleTypeName,
        ruleType: this.props.ruleType,
        visualStateIndexOverride: this.state.visualStateIndexOverride,
      }
    );
  }
}

import React, { Component } from "react";
import AudioPlayer from "react-h5-audio-player";
import { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import * as SoundRuleStyles from "../styles/SoundRule.module.css";
// UI framework component imports
import { Menu, Dropdown, Button as AntButton } from "antd";
import { DownOutlined } from "@ant-design/icons";

export default class ImmutableWeaponRule extends Component {
  // Properties used by this component:
  // soundMapping, itemName, visualStateIndex

  constructor(props) {
    super(props);

    this.state = {
      picker: this.props.soundMapping,
      visualStateIndexOverride: 0,
    };
  }
  // --- Functions for component state index 0 (1 of 2) ---

  handleSoundSelect = (event) => {
    this.setState({ picker: { Sound: event.key } });

    if (!this.props.ruleId || !this.props.soundProfileConfig) {
      return;
    }

    const config = this.props.soundProfileConfig;
    const currentRule = this.props.itemName;
    var updatedConfig = { ...config };
    updatedConfig[this.props.ruleId][currentRule] = {
      Sound: event.key,
    };

    this.setState({ soundProfileConfig: updatedConfig });
  };

  buildSoundFolderDropdown = (folder) => {
    return (
      // check to see if folder has children
      <Menu.SubMenu
        key={folder.id}
        title={folder.name}
        className={folder.name}
        popupClassName="DropDownSizeLimitStyle"
      >
        {this.props.foldersProvider.foldersByParentId[folder.id]?.map(
          this.buildSoundFolderDropdown
        )}
        {this.props.soundsProvider.soundsByFolderId[folder.id]?.map(
          // check if it has sounds
          (sound) => {
            return (
              <Menu.Item key={sound.id} className={sound.name}>
                {sound.name}
              </Menu.Item>
            );
          }
        )}
      </Menu.SubMenu>
    );
  };

  renderState0() {
    const soundId = this.state.picker.Sound || this.state.picker;
    const audioSrcUrl = `/api/v1/my-sounds/sound-data/${soundId}`;
    const style_state0_elSoundPlayer = {
      pointerEvents: "auto",
    };
    let transformPropValue_picker = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //

      // input = { Sound: "21281f6c-616d-41f0-9b4d-db8d2b8bc392" }
      // or input = "NoSound"

      if (!input || input === "NoSound") {
        return null;
      } else {
        return input["Sound"];
      }
    };

    let selection_picker = this.state.picker;
    // Source datasheet and selected data column for picker element 'picker'
    const sortedSounds = Object.values(
      this.props.soundsProvider.userSounds
    ).concat(Object.values(this.props.soundsProvider.defaultSounds));

    selection_picker = transformPropValue_picker(selection_picker);

    const value_text = this.props.itemName;

    const style_state0_elText564202 = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
    };

    const folderSoundMenu = this.props.foldersProvider.foldersByParentId["root"]
      .sort((x, y) => {
        if (x.is_global === y.is_global) {
          return x.name < y.name ? -1 : 1;
        }
        return x.is_global ? -1 : 1;
      })
      .map(this.buildSoundFolderDropdown);

    const menu = (
      <Menu onClick={this.handleSoundSelect}>{folderSoundMenu}</Menu>
    );

    return (
      <div className={SoundRuleStyles.SoundRule}>
        <div className={SoundRuleStyles.foreground}>
          <div className={SoundRuleStyles.state0_elSpacer583816} />
          <div
            className={SoundRuleStyles.state0_elSoundPlayer_immutable}
            style={style_state0_elSoundPlayer}
          >
            <AudioPlayer
              src={audioSrcUrl}
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
              showJumpControls={false}
              style={{ width: "250px", padding: "0px", "margin-top": "5px" }}
              customVolumeControls={[]}
              customAdditionalControls={[]}
              layout="horizontal-reverse"
            />
          </div>
          <div className={`antd ${SoundRuleStyles.state0_elPicker_immutable}`}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <AntButton
                className="ant-dropdown-link SoundSelector"
                style={{ width: 180, textAlign: "left" }}
                onClick={(e) => e.preventDefault()}
              >
                {(function () {
                  const maybeSound = sortedSounds.find(
                    (maybeItem) => maybeItem["id"] === selection_picker
                  );
                  return maybeSound === undefined
                    ? "Select Sound"
                    : maybeSound.name;
                })()}
                <DownOutlined className={SoundRuleStyles.antdDropArrow} />
              </AntButton>
            </Dropdown>
          </div>

          <div
            className={`baseFont ${SoundRuleStyles.state0_elText564202_immutable}`}
            style={style_state0_elText564202}
          >
            <div>
              {value_text !== undefined ? (
                value_text
              ) : (
                <span className="propValueMissing">
                  Oops something is missing.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // --- Functions for component state index 1 (2 of 2) ---

  onClick_state1_elButton = (ev) => {
    this.setState({ visualStateIndexOverride: 0 });

    // Enable Button
    if (!this.props.ruleId || !this.props.soundProfileConfig) {
      return;
    }

    const config =
      this.state.soundProfileConfig || this.props.soundProfileConfig;
    const currentRule = this.props.itemName;
    var updatedConfig = { ...config };
    updatedConfig[this.props.ruleId][currentRule] = {
      Sound: this.state.picker,
    };

    this.setState({ soundProfileConfig: updatedConfig });
  };

  render() {
    switch (parseInt(this.state.visualStateIndexOverride, 10)) {
      default:
      case 0:
        return this.renderState0();
    }
  }
}

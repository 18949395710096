import React, { Component } from "react";
import btn_icon_22783 from "../images/btn_icon_22783.png";
import * as CRStyles from "../styles/SoundProfileRow.module.css";
// UI framework component imports
import Checkbox from "muicss/lib/react/checkbox";
import Button from "muicss/lib/react/button";
import { CurrentUserContext } from "../server-state/CurrentUserContext";

import { Select } from "antd";
import Container from "muicss/lib/react/container";
import Col from "muicss/lib/react/col";
import Row from "muicss/lib/react/row";

class CharacterRow extends Component {
  // Properties used by this component:
  // currentUser, updateCurrentUser, CharacterProvider, visualStateIndex

  constructor(props) {
    super(props);

    this.state = {
      picker: "",
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  // --- Functions for component state index 0 (1 of 2) ---
  handleXButtonClick = (ev) => {
    this.handleDeleteCharacter();
  };

  handleDeleteCharacter = () => {
    let shouldDelete = window.confirm(
      `Are you sure you want to delete ${this.props.character.character_name}?`
    );

    if (shouldDelete) {
      this.props.characterProvider.deleteCharacter(this.props.character);
    }
  };

  profile_picker_change = (event) => {
    this.props.characterProvider.updateCharacter({
      ...this.props.character,
      character_sound_profile_id: event,
    });
  };

  checkboxChanged_active = (event) => {
    // checked here implies it's going from unchecked to checked
    var updatedUser = {};
    if (event.target.checked) {
      this.setState({ active: "true" });
      updatedUser = {
        ...this.context.user,
        active_character: this.props.character.id,
      };
    } else {
      this.setState({ active: "false" });
      updatedUser = { ...this.context.user, active_character: null };
    }

    this.context.updateCurrentUser(updatedUser);
  };

  style_state_elCard217157 = {
    width: "100%",
    height: "100%",
  };
  style_state_elCard217157_outer = {
    backgroundColor: "white",
    filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
    overflow: "visible",
  };
  style_state_elRectangle130967 = {
    background: "rgba(236, 236, 236, 1.000)",
    display: "none",
  };

  render() {
    const value_text = this.props.character
      ? this.props.character.character_name
      : "";

    const style_character_name = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
      fontWeight: "bold",
      marginTop: "7px",
      overflow: "hidden",
    };

    const style_X_Button = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_22783 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      color: "(null)",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
      float: "right",
      top: "4px",
    };

    let checked_active = this.context.user
      ? this.context.user.active_character === this.props.character.id
      : false;

    const style_check_box = {
      top: "7px",
      textAlign: "left",
      pointerEvents: "auto",
    };

    const style_Profile_Picker = {
      pointerEvents: "auto",
      overflowX: "clip",
      width: "275px",
    };

    return (
      <div className={CRStyles.SoundProfileRow}>
        <div className="background">
          <div
            className={`containerMinHeight ${CRStyles.state0_elCard217157}`}
            style={this.style_state_elCard217157_outer}
          >
            <div className="cardBg" style={this.style_state_elCard217157} />
          </div>

          <div
            className={CRStyles.state0_elRectangle130967}
            style={this.style_state_elRectangle130967}
          />
        </div>
        <div className={`layoutFlow ${CRStyles.layoutFlow}`}>
          <Container fluid={true}>
            <Row>
              <Col md="2">
                <div className={CRStyles.state0_elActive853462}>
                  <Checkbox
                    className="RowMarginSpacer baseFont"
                    style={style_check_box}
                    label={"Active"}
                    onChange={this.checkboxChanged_active}
                    checked={
                      checked_active === "true" ||
                      checked_active === true ||
                      "" + checked_active === "1"
                    }
                  />
                </div>
              </Col>
              <Col md="3">
                <div className={CRStyles.state0_elText177450}>
                  <div className="baseFont" style={style_character_name}>
                    <div>
                      {value_text !== undefined ? (
                        value_text
                      ) : (
                        <span className="propValueMissing">
                          Oops something is missing.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="3" md-offset="1">
                <div className="character_Sound_profile_Picker">
                  <Select
                    style={style_Profile_Picker}
                    onChange={this.profile_picker_change}
                    value={this.props.character.character_sound_profile_id}
                  >
                    <option value="" hidden></option>
                    {this.props.soundProfiles.map((profile) => {
                      return <option value={profile.id}>{profile.name}</option>;
                    })}
                  </Select>
                </div>
              </Col>
              <Col md="1" md-offset="2">
                <Button
                  style={style_X_Button}
                  size="small"
                  variant="flat"
                  onClick={this.handleXButtonClick}
                />
              </Col>
            </Row>
          </Container>
          <div className={CRStyles.state1_elSpacer2}>
            <div />
          </div>
        </div>
      </div>
    );
  }
}

CharacterRow.contextType = CurrentUserContext;

export default CharacterRow;

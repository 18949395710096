import React, { Component } from "react";
import { Router } from "@reach/router";
import "./styles/App.css";
import "./styles/style.css";
import SoundProfileEditorScreen from "./page-sound-profiles/SoundProfileEditorScreen.js";
import SoundManager from "./SoundManager";
import LoginFailedScreen from "./LoginFailedScreen.js";
import WheelOfFrustration from "./wheel_of_frustration";
import Games from "./Games";
import NavBar from "./NavBar";
import AdminPage from "./AdminPage.js";
import { CurrentUser } from "./server-state/CurrentUserContext";
import { SoundProfileProvider } from "./server-state/SoundProfileProvider";
import { CharacterProvider } from "./server-state/CharacterProvider";
import { isLoggedIn } from "./server-state/util";
import { AdminProvider } from "./server-state/AdminProvider";
import { SoundsProvider } from "./server-state/SoundsProvider";
import { FoldersProvider } from "./server-state/FoldersProvider";
import { navigate } from "gatsby";
import { Tracing } from "tracing-utils/tracing";
import { TracingErrorBoundary } from "./tracing/TracingErrorBoundary";
import { ErrorBoundaryName } from "./tracing/SpanNames";
// This component redirects to the top level home
// breaking out of whatever router nesting is currently
// being used. It only works on the client side but is
// safe to include (renders to an empty element) in the
// server context.
function RedirectHome() {
  if (typeof window !== `undefined`) {
    navigate("/");
  }

  return <React.Fragment></React.Fragment>;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenTransitionForward: true,
    };
  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = "narrow-phone";
    else if (w < 768) formatId = "wide-phone";
    else if (w < 1024) formatId = "narrow-tablet";
    else formatId = "wide-tablet";
    if (formatId !== this.state.screenFormatId) {
      this.setState({ screenFormatId: formatId });
    }
  };

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener("resize", this.windowDidResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  checkLogIn = (element) => {
    if (!isLoggedIn()) {
      // Path here allows this to be used
      // in a router to
      return <RedirectHome path="/" />;
    } else {
      return element;
    }
  };

  render() {
    return (
      <div className="App">
        <Tracing>
          <TracingErrorBoundary name={ErrorBoundaryName.APP}>
            <Router>
              {this.checkLogIn(
                this.makeElementForScreen(
                  "/soundprofileeditor",
                  "soundprofileeditor"
                )
              )}
              {this.checkLogIn(this.makeElementForScreen("/sounds", "sounds"))}
              {this.checkLogIn(
                this.makeElementForScreen("/adminpage", "adminpage")
              )}
              {this.makeElementForScreen("/loginfailed", "loginfailed")}
              {this.makeElementForScreen("/games", "games")}
              {this.makeElementForScreen(
                "/wheel-of-frustration",
                "wheel-of-frustration"
              )}
              <RedirectHome path="/*" default />
            </Router>
          </TracingErrorBoundary>
        </Tracing>
      </div>
    );
  }

  makeElementForScreen(path, screenId) {
    let screenProps = {
      atTopOfScreenStack: true,
      transitionForward: true,
      appActions: this,
      dataSheets: this.dataSheets,
      locStrings: this.locStrings,
      deviceInfo: {
        screenFormatId: this.state.screenFormatId,
      },
    };
    return (
      <SoundProfileProvider
        path={path}
        render={(profileProvider) => {
          return (
            <FoldersProvider
              render={(foldersProvider) => {
                return (
                  <SoundsProvider
                    render={(soundsProvider) => {
                      return (
                        <CharacterProvider
                          render={(characterProvider) => {
                            if (isLoggedIn()) {
                              screenProps = {
                                ...screenProps,
                                profileProvider,
                                foldersProvider,
                                soundsProvider,
                                characterProvider,
                              };
                            }
                            return this.buildInnerScreen(screenId, screenProps);
                          }}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
    );
  }

  buildInnerScreen(screenId, screenProps) {
    switch (screenId) {
      default:
        return null;
      case "soundprofileeditor":
        return (
          <>
            <CurrentUser>
              <SoundProfileEditorScreen {...screenProps} />
              <NavBar {...screenProps} />
            </CurrentUser>
          </>
        );
      case "sounds":
        return (
          <>
            <CurrentUser>
              <SoundManager {...screenProps} />
              <NavBar {...screenProps} />
            </CurrentUser>
          </>
        );
      case "adminpage":
        return (
          <>
            <CurrentUser>
              <AdminProvider
                render={(adminProvider) => {
                  screenProps = {
                    ...screenProps,
                    adminProvider,
                  };
                  return <AdminPage {...screenProps} />;
                }}
              />
              <NavBar {...screenProps} />
            </CurrentUser>
          </>
        );
      case "loginfailed":
        return (
          <>
            <LoginFailedScreen {...screenProps} />
          </>
        );
      case "wheel-of-frustration":
        return (
          <>
            <CurrentUser>
              <WheelOfFrustration {...screenProps} />
              <NavBar {...screenProps} />
            </CurrentUser>
          </>
        );
      case "games":
        return (
          <>
            <CurrentUser>
              <Games {...screenProps} />
              <NavBar {...screenProps} />
            </CurrentUser>
          </>
        );
    }
  }
}
export default App;

import React, { Component } from "react";
import SoundProfileRow from "./SoundProfileRow";
import ImmutableSoundProfileRow from "./ImmutableSoundProfileRow";
import CharacterRow from "./CharacterRow";
import { withTrace } from "../tracing/tracing";
import { SpanName } from "../tracing/SpanNames";
import { TracingErrorBoundary } from "../tracing/TracingErrorBoundary";
import { ErrorBoundaryName } from "../tracing/SpanNames";

// UI framework component imports
import Button from "muicss/lib/react/button";
import Container from "muicss/lib/react/container";

export default class SoundProfileEditorScreen extends Component {
  // Properties used by this component:
  // appActions, deviceInfo, profileProvider

  constructor(props) {
    super(props);

    this.state = {
      profile_name: "",
      character_name: "",
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  handleNewSoundProfileClick = (ev) => {
    if (this.state.profile_name === "") {
      window.alert("Enter a name for your sound profile.");
    } else {
      this.createSoundProfile();
    }
  };

  handleNewcharacterClick = (ev) => {
    if (this.state.character_name === "") {
      window.alert("Enter a name for your character.");
    } else {
      this.createCharacter();
    }
  };

  textInputChanged_Profile_Name = (event) => {
    this.setState({ profile_name: event.target.value });
  };

  textInputChanged_Character_Name = (event) => {
    this.setState({ character_name: event.target.value });
  };

  createSoundProfile = () => {
    this.props.profileProvider.createSoundProfile({
      name: this.state.profile_name,
    });
    this.setState({ profile_name: "" });
  };

  createCharacter = () => {
    this.props.characterProvider.createCharacter({
      character_name: this.state.character_name,
    });
    this.setState({ character_name: "" });
  };

  render() {
    return withTrace(
      SpanName.SOUND_PROFILES_PAGE_RENDER,
      () => {
        let layoutFlowStyle = {};
        let baseStyle = {};

        if (
          this.props.transitionId &&
          this.props.transitionId.length > 0 &&
          this.props.atTopOfScreenStack &&
          this.props.transitionForward
        ) {
          baseStyle.animation = "0.25s ease-in-out " + this.props.transitionId;
        }
        if (!this.props.atTopOfScreenStack) {
          layoutFlowStyle.height = "100vh";
          layoutFlowStyle.overflow = "hidden";
        }

        const style_elBackground = {
          width: "100%",
          height: "100%",
        };
        const style_elBackground_outer = {
          backgroundColor: "#f6f6f6",
        };

        // Source items and any special components used for list/grid element 'list'.
        let profiles = Object.values(this.props.profileProvider.soundProfiles);

        // Gets the users characters and orders them alphabetically
        let characters = Object.values(
          this.props.characterProvider.characters
        ).sort((a, b) => (a.character_name < b.character_name ? -1 : 1));

        // source default profile
        let defaultProfile = this.props.profileProvider.defaultProfile;

        const style_elList = {
          height: "auto", // This element is in scroll flow
        };

        const style_elButton = {
          display: "block",
          color: "white",
          textAlign: "center",
          cursor: "pointer",
          pointerEvents: "auto",
        };

        const style_elName = {
          display: "block",
          backgroundColor: "white",
          paddingLeft: "1rem",
          boxSizing: "border-box", // ensures padding won't expand element's outer size
          textAlign: "left",
          pointerEvents: "auto",
        };

        return (
          <TracingErrorBoundary name={ErrorBoundaryName.SOUND_PROFILE_EDITOR}>
            <Container
              fluid={true}
              className="AppScreen SoundProfileEditorScreen"
              style={baseStyle}
            >
              <div className="background">
                <div
                  className="containerMinHeight elBackground"
                  style={style_elBackground_outer}
                >
                  <div className="appBg" style={style_elBackground} />
                </div>
              </div>

              <div className="layoutFlow" style={layoutFlowStyle}>
                <div className="defaultProfileHeader elText">
                  <h1>Default Profile</h1>
                  <p>
                    This is the sound profile that is used as the core for all
                    sound profiles. It fills in sounds in your custom profiles
                    that you don't have sounds for.
                  </p>
                </div>

                <div className="hasNestedComps defaultSoundProfile">
                  {defaultProfile ? (
                    <ImmutableSoundProfileRow
                      appActions={this.props.appActions}
                      deviceInfo={this.props.deviceInfo}
                      locStrings={this.props.locStrings}
                      soundProfile={defaultProfile}
                      soundsProvider={this.props.soundsProvider}
                      foldersProvider={this.props.foldersProvider}
                    />
                  ) : null}
                </div>

                <div className="userProfileHeader elText">
                  <h1>Custom Profiles</h1>
                  <p>
                    Custom Profiles is where you can create your very own sound
                    profiles! In a profile you can map sounds to different
                    actions. You can override the default sounds using a custom
                    profile. For example mapping Fireball to a new sound will
                    override the default Fireball sound. After editing your
                    profile don't forget to press <b>save</b> on the profile!
                    The active checkbox is saved automatically and means that
                    the profile will be merged with your friends active profiles
                    and used in your games.
                  </p>
                </div>

                <div className="elButton">
                  <Button
                    id="createNewProfileButton"
                    className="actionFont"
                    style={style_elButton}
                    color="accent"
                    onClick={this.handleNewSoundProfileClick}
                  >
                    New
                  </Button>
                </div>

                <div className="elName">
                  <input
                    id="newProfileNameTextbox"
                    className="baseFont"
                    style={style_elName}
                    type="text"
                    placeholder={"My Sound Profile"}
                    onChange={this.textInputChanged_Profile_Name}
                    value={this.state.profile_name}
                  />
                </div>

                <div className="hasNestedComps elList">
                  <ul style={style_elList}>
                    {profiles.map((profile) => {
                      let itemComp = (
                        <SoundProfileRow
                          appActions={this.props.appActions}
                          deviceInfo={this.props.deviceInfo}
                          locStrings={this.props.locStrings}
                          profileProvider={this.props.profileProvider}
                          soundProfile={profile}
                          soundsProvider={this.props.soundsProvider}
                          foldersProvider={this.props.foldersProvider}
                        />
                      );
                      return <li key={profile.id}>{itemComp}</li>;
                    })}
                    <div
                      className="marker"
                      ref={(el) => (this._elList_endMarker = el)}
                    />
                  </ul>
                </div>

                <div className="userProfileHeader elText">
                  <h1>Characters</h1>
                  <p>
                    Characters allows you to apply a sound profile specifically
                    for your Avrae character. These sounds will not be
                    overwritten by other user's active profiles! Additionally,
                    your characters Custom Sounds will only work for you. You
                    can have both a Custom Profile and a character active, and
                    they can both use the same profile. The characters name must
                    match the name displayed by Avrae exactly.
                  </p>
                </div>
                <div className="elButton">
                  <Button
                    id="createNewCharacterButton"
                    className="actionFont"
                    style={style_elButton}
                    color="accent"
                    onClick={this.handleNewcharacterClick}
                  >
                    New
                  </Button>
                </div>

                <div className="elName">
                  <input
                    id="newCharacterNameTextbox"
                    className="baseFont"
                    style={style_elName}
                    type="text"
                    placeholder={"My Avrae Character"}
                    onChange={this.textInputChanged_Character_Name}
                    value={this.state.character_name}
                  />
                </div>

                <div className="hasNestedComps elList">
                  <ul style={style_elList}>
                    {characters.map((character) => {
                      let itemComp = (
                        <CharacterRow
                          appActions={this.props.appActions}
                          deviceInfo={this.props.deviceInfo}
                          locStrings={this.props.locStrings}
                          characterProvider={this.props.characterProvider}
                          soundProfiles={profiles}
                          character={character}
                        />
                      );
                      return <li key={character.id}>{itemComp}</li>;
                    })}
                    <div
                      className="marker"
                      ref={(el) => (this._elList_endMarker = el)}
                    />
                  </ul>
                </div>
              </div>
              <div className="BottomScrollSpacer">
                <div />
              </div>
            </Container>
          </TracingErrorBoundary>
        );
      },
      { props: this.props, state: this.state }
    );
  }
}

import React, { Component, Fragment } from "react";
import { CurrentUserContext } from "./server-state/CurrentUserContext";
import * as S2SStyles from "./styles/Sounds2Screen.module.css";
import { Modal, Input, Checkbox } from "antd";
import { Button, Box, LinearProgress, Typography } from "@mui/material";
import { State as SoundsProviderState } from "./server-state/SoundsProvider";
import { TracingErrorBoundary } from "./tracing/TracingErrorBoundary";
import { ErrorBoundaryName, SpanName } from "./tracing/SpanNames";
import { withTrace } from "./tracing/tracing";
import { createFFmpeg, FFmpeg } from "@ffmpeg/ffmpeg";
import { encode } from "base64-arraybuffer";

export interface Props {
  soundsProvider: SoundsProviderState;
  userVisible: boolean;
  toggleUserModal: Function;
  selectedFolder: string;
}

export interface State {
  isGlobalSoundModalVisible: boolean;
  newSoundName: string;
  adminNewSoundName: string;
  newSoundData: string;
  adminNewSoundData: string;
  ffmpeg: FFmpeg;
  convertingAudio: boolean;
  conversionComplete: boolean;
  conversionFailed: boolean;
  conversionProgress: number;
  soundTooBig: boolean;
  soundSize: number;
  useSmallBitRate: boolean;
  inputFile: File | undefined;
}

class SoundUploadModal extends Component<Props, State> {
  MAXLENGTH: number;
  constructor(props: Props) {
    super(props);
    this.state = {
      isGlobalSoundModalVisible: false,
      newSoundName: "",
      adminNewSoundName: "",
      newSoundData: "",
      adminNewSoundData: "",
      ffmpeg: createFFmpeg({ log: false }),
      convertingAudio: false,
      conversionComplete: false,
      conversionFailed: false,
      soundTooBig: false,
      conversionProgress: 0,
      soundSize: 0,
      useSmallBitRate: false,
      inputFile: undefined,
    };
    this.MAXLENGTH = 1 * 1024 * 1024; // 1MB
  }

  componentDidMount(): void {
    this.state.ffmpeg.load();
  }

  handleUpload = () => {
    if (!this.state.newSoundName || this.state.newSoundName === "") {
      alert("Sound name is required");
      return;
    }

    if (!this.state.newSoundData || this.state.newSoundData === "") {
      alert("Sound file is required");
      return;
    }
    let newSound = {
      name: this.state.newSoundName,
      base64_ogg_sound: this.state.newSoundData,
      parent_folder: this.props.selectedFolder,
    };
    this.props.soundsProvider.createUserSound(newSound);
    this.setState({
      newSoundName: "",
      newSoundData: "",
      conversionComplete: false,
      inputFile: undefined,
    });
    this.toggleUser();
  };

  convertSoundToOGG = async (uint8Array: Uint8Array, fileName: string) => {
    await this.state.ffmpeg.FS("writeFile", fileName, uint8Array);
    this.state.ffmpeg.setProgress(({ ratio }) => {
      this.setState({ conversionProgress: ratio });
    });
    var preset = this.state.useSmallBitRate ? ["-b:a", "48k"] : ["", ""];
    await this.state.ffmpeg.run(
      "-i",
      fileName,
      "-c:a",
      "libopus",
      preset[0],
      preset[1],
      "-vn",
      "userSound.ogg"
    );
    const int8ArrayData = await this.state.ffmpeg.FS(
      "readFile",
      "userSound.ogg"
    );
    return int8ArrayData;
  };

  updateNewSoundData = async (clickEvent: any) => {
    document.body.style.cursor = "wait";
    let file: File;
    if (clickEvent?.target?.files !== undefined) {
      file = clickEvent.target.files[0];
      this.setState({ inputFile: clickEvent.target.files[0] });
    } else {
      file = this.state.inputFile!;
    }
    const arrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const isOpus = file.type === "audio/ogg" || file.type === "video/ogg";
    const isValidOpus = isOpus && uint8Array.length < this.MAXLENGTH;
    const isValidOtherType = !isOpus && uint8Array.length < this.MAXLENGTH * 5;
    var base64_Data = "";
    this.setState({
      soundTooBig: false,
      conversionFailed: false,
      conversionComplete: false,
      soundSize: 0,
    });
    try {
      if (!isValidOpus && !isValidOtherType) {
        this.setState({
          soundTooBig: true,
          convertingAudio: false,
          soundSize: uint8Array.length,
        });
        return;
      } else if (isValidOpus && !this.state.useSmallBitRate) {
        base64_Data = encode(uint8Array);
      } else if (isValidOtherType || this.state.useSmallBitRate) {
        this.setState({ convertingAudio: true });
        var int8ArrayData = await this.convertSoundToOGG(uint8Array, file.name);
        if (int8ArrayData.length > this.MAXLENGTH) {
          this.setState({
            soundTooBig: true,
            convertingAudio: false,
            soundSize: int8ArrayData.length,
          });
          return;
        }
        base64_Data = encode(int8ArrayData);
      }
      this.setState({
        newSoundData: base64_Data,
        convertingAudio: false,
        conversionComplete: true,
      });
    } catch (err) {
      this.setState({
        convertingAudio: false,
        conversionComplete: false,
        conversionFailed: true,
      });
    } finally {
      document.body.style.cursor = "default";
    }
  };

  updateNewSoundName = (event: any) => {
    this.setState({ newSoundName: event.target.value });
  };

  handleAdminUpload = () => {
    if (!this.state.adminNewSoundName || this.state.adminNewSoundName === "") {
      alert("Global sound name is required");
      return;
    }

    if (!this.state.adminNewSoundData || this.state.adminNewSoundData === "") {
      alert("Global sound file is required");
      return;
    }
    let newGlobalSound = {
      name: this.state.adminNewSoundName,
      is_global: true,
      base64_ogg_sound: this.state.adminNewSoundData,
    };
    this.props.soundsProvider.createGlobalSound(newGlobalSound);

    this.setState({ adminNewSoundName: "" });
  };
  updateAdminNewSoundData = (event: any) => {
    let file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      let base64_data = window.btoa(event.target.result);
      this.setState({ adminNewSoundData: base64_data });
    };
    reader.readAsBinaryString(file);
  };

  updateAdminNewSoundName = (event: any) => {
    this.setState({ adminNewSoundName: event.target.value });
  };

  toggleUser = () => {
    this.props.toggleUserModal();
  };

  toggleGlobalModal = () => {
    this.setState({
      isGlobalSoundModalVisible: !this.state.isGlobalSoundModalVisible,
    });
  };

  toggleBitRate = (event: any) => {
    this.setState({
      useSmallBitRate: !this.state.useSmallBitRate,
    });
    if (this.state.inputFile !== undefined) {
      this.updateNewSoundData(event);
    }
  };

  private buildNewGlobalSoundInputForm() {
    return (
      <div className={S2SStyles.soundUploader}>
        <div className="newSoundName">
          <p>New Global sound name</p>
          <Input
            className="baseFont"
            type="text"
            placeholder="Thunderclap"
            onChange={this.updateAdminNewSoundName}
            value={this.state.adminNewSoundName}
            maxLength={100}
          />
        </div>
        <p>Global sound file</p>
        <Input
          type="file"
          className={`soundFileInput ${S2SStyles.soundFileInput}`}
          name="soundFileInput"
          accept="audio/*"
          onChange={this.updateAdminNewSoundData}
        />
      </div>
    );
  }

  private buildUploadGlobalSoundButton(): React.ReactNode {
    return (
      <Button
        className={`uploadButton ${S2SStyles.uploadButton}`}
        onClick={this.handleAdminUpload}
        variant="contained"
        color="primary"
        sx={{ fontSize: 12 }}
      >
        Global Upload
      </Button>
    );
  }

  private buildNewSoundInputForm() {
    return (
      <Fragment>
        <div className={S2SStyles.soundUploader}>
          <h3>New sound name</h3>
          <Input
            className="baseFont"
            id="newSoundName"
            type="text"
            placeholder="Thunderclap"
            onChange={this.updateNewSoundName}
            value={this.state.newSoundName}
            maxLength={100}
          />
          <h3>New sound file</h3>
          <Checkbox
            value={this.state.useSmallBitRate}
            onChange={this.toggleBitRate}
            disabled={this.state.convertingAudio}
          >
            Use recommended bit rate
          </Checkbox>
          <Input
            type="file"
            id="soundFileInput"
            className={`${S2SStyles.soundFileInput}`}
            name="soundFileInput"
            accept="audio/*"
            onChange={this.updateNewSoundData}
          />
        </div>
        {this.state.conversionFailed === true && (
          <h4 style={{ color: "red" }}>
            Oops something went wrong! If the problem persists please reach us
            at the development{" "}
            <a
              href="https://discord.gg/XASDPeB"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>
            .
          </h4>
        )}
        {this.state.soundTooBig === true && (
          <h4 style={{ color: "red" }}>
            Sound too Large: {this.state.soundSize / this.MAXLENGTH} MB
          </h4>
        )}
        {this.state.convertingAudio === true && (
          <Box sx={{ flexGrow: 0, ml: 0, marginLeft: "20px" }}>
            <Box sx={{ minWidth: 35 }}>
              <Typography sx={{ fontSize: "1.5rem" }} variant="body2">
                Conversion Progress:
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LinearProgress
                sx={{
                  width: "120px",
                  height: "1.5rem",
                  marginRight: "5px",
                  marginBottom: "3px",
                }}
                variant="determinate"
                value={Math.round(this.state.conversionProgress * 100)}
              />
              <Typography sx={{ fontSize: "1.5rem" }} variant="body2">
                {this.state.conversionProgress < 0 && `0%`}
                {this.state.conversionProgress >= 0 &&
                  `${Math.round(this.state.conversionProgress * 100)}%`}
              </Typography>
            </Box>
          </Box>
        )}
      </Fragment>
    );
  }

  private getSoundUploadDescription(): React.ReactNode {
    return (
      <p>Upload your own sounds (accepts most audio file types) under 1MB!</p>
    );
  }

  private buildUploadSoundButton(): React.ReactNode {
    return (
      <Button
        disabled={!this.state.conversionComplete}
        className={`uploadButton ${S2SStyles.uploadButton}`}
        onClick={this.handleUpload}
        variant="contained"
        color="primary"
        sx={{ fontSize: 12 }}
      >
        Upload
      </Button>
    );
  }

  private buildOpenGlobalUploadButton(): React.ReactNode {
    return (
      <Button
        color="warning"
        variant="contained"
        onClick={this.toggleGlobalModal}
        sx={{ fontSize: 12 }}
      >
        Admin Upload
      </Button>
    );
  }

  render() {
    return withTrace(SpanName.SOUND_UPLOAD_MODAL, () => (
      <TracingErrorBoundary name={ErrorBoundaryName.SOUND_UPLOAD_MODAL}>
        <div>
          <Modal
            title="Upload a new Sound!"
            visible={this.props.userVisible}
            onCancel={this.toggleUser}
            footer={[
              this.context.user &&
                this.context.user.is_admin &&
                this.buildOpenGlobalUploadButton(),
              this.buildUploadSoundButton(),
            ]}
          >
            {this.getSoundUploadDescription()}
            {this.buildNewSoundInputForm()}
          </Modal>
          <Modal
            title="Upload a new Global Sound!"
            visible={this.state.isGlobalSoundModalVisible}
            onCancel={this.toggleGlobalModal}
            footer={[this.buildUploadGlobalSoundButton()]}
          >
            {this.buildNewGlobalSoundInputForm()}
          </Modal>
        </div>
      </TracingErrorBoundary>
    ));
  }
}

SoundUploadModal.contextType = CurrentUserContext;

export default SoundUploadModal;

import React, { Component } from "react";
import * as FolderTreeStyles from "./styles/FolderTree.module.css";
import { DeleteOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Modal, Input } from "antd";
import { Folder } from "./server-state/FoldersProvider";

export interface Props {
  isContextMenuVisible: boolean;
  popup: {
    folder: Folder | null;
    canEditNameAndDelete: boolean;
    canAddFolder: boolean;
    x: number;
    y: number;
  };
  onClickDeleteButton: () => void;
  handleUpdateFolderName: (updateFolderName: string | undefined) => void;
  handleNewFolder: (newFolderName: string | undefined) => void;
  toggleContextMenu: (open: boolean, rename: boolean) => void;
  isRenamingFolder: boolean;
}

export interface State {
  updateFolderName: string | undefined;
  newFolderName: string | undefined;
}

class ModalFolderTree extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      updateFolderName: this.props.popup.folder?.name,
      newFolderName: "",
    };
  }

  textInputChanged_Folder_Name = (event: any) => {
    this.setState({ updateFolderName: event.target.value });
  };
  textInputChanged_New_Folder_Name = (event: any) => {
    this.setState({ newFolderName: event.target.value });
  };

  private buildModalHeader() {
    return this.props.isRenamingFolder === false ? (
      <span style={{ display: "block" }}>
        {this.props.popup.canEditNameAndDelete && (
          <Button
            className="RenameFolder"
            onClick={() => {
              this.props.toggleContextMenu(true, true);
              this.setState({
                updateFolderName: this.props.popup.folder?.name,
              });
            }}
          >
            <EditOutlined />
          </Button>
        )}
        <h4
          style={{
            marginTop: 5,
            marginInline: 10,
            marginBottom: 0,
            display: "inline",
          }}
        >
          {this.props.popup.folder?.name}
        </h4>
      </span>
    ) : (
      <Input.Group compact>
        <Button
          className="SubmitNewFolderName"
          onClick={() => {
            this.props.handleUpdateFolderName(this.state.updateFolderName);
          }}
        >
          <CheckOutlined />
        </Button>
        <Input
          className="UpdateFolderNameInput"
          style={{ width: "calc(100% - 75px)" }}
          defaultValue={this.props.popup.folder?.name}
          onChange={this.textInputChanged_Folder_Name}
        ></Input>
      </Input.Group>
    );
  }

  render() {
    return (
      <Modal
        title={[this.buildModalHeader()]}
        className={`${FolderTreeStyles.antDModal}`}
        style={{
          left: `${this.props.popup.x}px`,
          top: `${this.props.popup.y}px`,
        }}
        visible={this.props.isContextMenuVisible}
        onCancel={() => this.props.toggleContextMenu(false, false)}
        footer={[
          <Button
            className="Close"
            key="back"
            onClick={() => this.props.toggleContextMenu(false, false)}
          >
            Close
          </Button>,
        ]}
      >
        {this.props.popup.canAddFolder && (
          <Input.Group compact>
            <Button
              className="SubmitNewFolder"
              onClick={() =>
                this.props.handleNewFolder(this.state.newFolderName)
              }
            >
              New
            </Button>
            <Input
              className="NewFolderName"
              style={{ width: "calc(100% - 75px)" }}
              placeholder={"New Folder Name"}
              onChange={this.textInputChanged_New_Folder_Name}
              value={this.state.newFolderName}
            ></Input>
          </Input.Group>
        )}

        {this.props.popup.canEditNameAndDelete && (
          <Button
            className="DeleteFolderButton"
            style={{
              marginTop: 10,
            }}
            onClick={this.props.onClickDeleteButton}
          >
            <DeleteOutlined />
            Delete
          </Button>
        )}
      </Modal>
    );
  }
}
export default ModalFolderTree;

import "antd/dist/antd.css";
import "../styles/index.css";
import "../styles/mui.min.css";
import App from "../App";
import * as React from "react";
import { Router } from "@reach/router";

import Seo from "../components/seo";
import { Tracing } from "tracing-utils/tracing";

const IndexPage = () => (
  <React.Fragment>
    <Tracing>
      <Seo />
      <Router basepath="/app">
        <App path="/*" default />
      </Router>
    </Tracing>
  </React.Fragment>
);

export default IndexPage;

import React, { Component } from "react";
import * as GamesStyles from "./styles/Games.module.css";
// UI framework component imports
import Container from "muicss/lib/react/container";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";
import { Link } from "@reach/router";
import WheelOfFrustrationImage from "./images/wheel-of-frustration.jpg";

export default class GamesScreen extends Component {
  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (
      this.props.transitionId &&
      this.props.transitionId.length > 0 &&
      this.props.atTopOfScreenStack &&
      this.props.transitionForward
    ) {
      baseStyle.animation = "0.25s ease-in-out " + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = "100vh";
      layoutFlowStyle.overflow = "hidden";
    }

    const style_elBackground = {
      width: "100%",
      height: "100%",
    };
    const style_elBackground_outer = {
      backgroundColor: "#f6f6f6",
    };

    return (
      <div className={`AppScreen ${GamesStyles.GamesScreen}`} style={baseStyle}>
        <div className="background">
          <div
            className="containerMinHeight elBackground"
            style={style_elBackground_outer}
          >
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>

        <div
          className={`layoutFlow ${GamesStyles.layoutFlow}`}
          style={layoutFlowStyle}
        >
          <div className={GamesStyles.elSpacer}>
            <div />
          </div>

          <h1 className={GamesStyles.Title}>Games</h1>

          <Container className={GamesStyles.gameGrid} fluid={true}>
            <Row>
              <Col md="4">
                <Link to="/app/wheel-of-frustration">
                  <img
                    src={WheelOfFrustrationImage}
                    alt="Wheel of Frustration"
                  />
                </Link>
                <div>
                  The code's right on the door. How hard could it be? Gah, why
                  do the other numbers keep changing!?
                </div>
              </Col>
            </Row>
          </Container>

          <div></div>
        </div>
      </div>
    );
  }
}

import React from "react";
import btn_icon_1027699 from "../images/btn_icon_1027699.png";
import btn_icon_341038 from "../images/btn_icon_341038.png";
import btn_icon_799768 from "../images/btn_icon_799768.png";
import SoundRule from "./SoundRule";
import { SortSounds } from "../server-state/util";
import * as SPRCStyles from "../styles/SoundProfileRuleConfig.module.css";
// UI framework component imports
import {
  Menu,
  Dropdown,
  Button as AntButton,
  Select,
  AutoComplete,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  Folder,
  State as FolderProviderState,
} from "../server-state/FoldersProvider";
import { SoundProfile } from "../server-state/SoundProfileProvider";
import {
  Sound,
  State as SoundsProviderState,
} from "../server-state/SoundsProvider";
import { withTrace } from "../tracing/tracing";
import { ErrorBoundaryName, SpanName } from "../tracing/SpanNames";
import { TracingErrorBoundary } from "../tracing/TracingErrorBoundary";

export interface Props {
  soundProfileConfig: SoundProfile["config"];
  ruleId: keyof SoundProfile["config"];
  ruleTypeName: string;
  ruleType: string;
  ruleCaseType: string;
  ruleTypeExample: string;
  appActions: unknown;
  locStrings: unknown;
  soundsProvider: SoundsProviderState;
  foldersProvider: FolderProviderState;
}

export interface State {
  soundProfileConfig: SoundProfile["config"] | null;
  newRuleName: string;
  picker: string;
  visualStateIndexOverride: number;
}

export default class SoundProfileRuleConfig extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      soundProfileConfig: null,
      newRuleName: "",
      picker: "",
      visualStateIndexOverride: 0,
    };
  }

  // --- Functions for component state index 0 (1 of 2) ---

  onClick_expandRuleConfig = () => {
    this.setState({ visualStateIndexOverride: 1 });
  };

  styleBackground = {
    width: "100%",
    height: "100%",
  };

  styleBackgroundContainer = {
    backgroundColor: "white",
    filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
    overflow: "visible",
  };

  DropDownSizeLimitStyle = {
    minWidth: "120px",
    maxHeight: "250px",
    overflow: "auto",
  };

  renderClosed() {
    const value_text = this.props.ruleTypeName;

    const style_state0_elText475640: React.CSSProperties = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
    };

    const style_state0_elIconButton2: React.CSSProperties = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_1027699 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "129.499%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    return (
      <div className={SPRCStyles.SoundProfileRuleConfig}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRCStyles.state0_elCard}`}
            style={this.styleBackgroundContainer}
          >
            <div className="cardBg" style={this.styleBackground} />
          </div>
        </div>

        <div className={`layoutFlow ${SPRCStyles.layoutFlow}`}>
          <div className={SPRCStyles.state0_elText475640}>
            <div className="baseFont" style={style_state0_elText475640}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={SPRCStyles.state0_elIconButton2}>
            <button
              className="actionFont expandRuleConfigButton"
              style={style_state0_elIconButton2}
              onClick={this.onClick_expandRuleConfig}
            />
          </div>
        </div>
      </div>
    );
  }

  // --- Functions for component state index 1 (2 of 2) ---

  onClick_collapseRuleConfig = () => {
    this.setState({ visualStateIndexOverride: 0 });
  };

  onClick_state1_elCreateRule = () => {
    if (!this.state.newRuleName || this.state.newRuleName === "") {
      window.alert(`Enter a value like ${this.props.ruleTypeExample}`);
      return;
    }

    if (!this.state.picker || this.state.picker === "") {
      window.alert("Select a sound for the rule.");
      return;
    }

    let newRuleKey: string | number = this.state.newRuleName;

    if (this.props.ruleCaseType === "lower") {
      newRuleKey = newRuleKey.toLowerCase();
    }
    if (this.props.ruleType === "numeric") {
      newRuleKey = parseInt(this.state.newRuleName);
    }

    if (
      this.props.ruleType === "numeric" &&
      typeof newRuleKey === "number" &&
      isNaN(newRuleKey)
    ) {
      window.alert("Entered value must be an integer");
      return;
    }

    if (newRuleKey in this.props.soundProfileConfig[this.props.ruleId]) {
      window.alert("Entered name already exists");
      return;
    }
    const config = this.props.soundProfileConfig;
    let sound_id = `${this.state.picker}`;
    config[this.props.ruleId][newRuleKey] = {
      Sound: sound_id,
    };

    this.setState({
      soundProfileConfig: config,
      picker: "",
      newRuleName: "",
    });
  };

  textInputChanged_newRuleName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ newRuleName: event.target.value });
  };

  Suggestion_Changed_newRuleName = (event: string) => {
    console.log(event);
    this.setState({ newRuleName: event });
  };

  dropDown_newRuleName = (event: string) => {
    this.setState({ newRuleName: event });
  };

  pickerValueChanged_picker = (event: any) => {
    this.setState({ picker: event.key });
  };

  onClick_state1_elDeleteRuleFunctionDeclaration = (ev: any) => {
    const itemName = ev;
    if (!this.props.ruleId || !this.props.soundProfileConfig) {
      return;
    }

    const config = this.props.soundProfileConfig;
    var updatedConfig = { ...config };
    delete updatedConfig[this.props.ruleId][itemName];

    this.setState({ soundProfileConfig: updatedConfig });
  };

  // create submenu html
  buildSoundFolderDropdown = (folder: Folder) => {
    return (
      // check to see if folder has children
      <Menu.SubMenu
        key={folder.id}
        title={folder.name}
        className={folder.name}
        popupClassName="DropDownSizeLimitStyle"
      >
        {this.props.foldersProvider.foldersByParentId[folder.id]?.map(
          this.buildSoundFolderDropdown
        )}
        {this.props.soundsProvider.soundsByFolderId[folder.id]?.map(
          (sound: Sound) => {
            return (
              <Menu.Item key={sound.id} className={sound.name}>
                {sound.name}
              </Menu.Item>
            );
          }
        )}
      </Menu.SubMenu>
    );
  };

  renderOpen() {
    const style_state1_elGrayBackground: React.CSSProperties = {
      width: "100%",
      height: "100%",
    };
    const style_state1_elGrayBackground_outer: React.CSSProperties = {
      backgroundColor: "#ececec",
    };

    const value_text = this.props.ruleTypeName;

    const style_state1_elText475640: React.CSSProperties = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
    };

    const style_state1_elIconButton2: React.CSSProperties = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_341038 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "150.614%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    const style_state1_elCreateWeaponRule: React.CSSProperties = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_799768 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "88.887%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    const style_state1_elNewWeaponName: React.CSSProperties = {
      display: "block",
      backgroundColor: "white",
      paddingLeft: "1rem",
      boxSizing: "border-box", // ensures padding won't expand element's outer size
      textAlign: "left",
      pointerEvents: "auto",
    };

    const style_state1_elinitiativePicker: React.CSSProperties = {
      pointerEvents: "auto",
    };

    let selection_picker = this.state.picker;
    // Source datasheet and selected data column for picker element 'picker'
    const sortedSounds: Sound[] = SortSounds(
      Object.values(this.props.soundsProvider.userSounds).concat(
        Object.values(this.props.soundsProvider.defaultSounds)
      )
    );

    const config = this.props.soundProfileConfig;
    const items_list = Object.entries(config[this.props.ruleId]).sort((a, b) =>
      a[0] < b[0] ? -1 : 1
    );
    const style_elList = {
      height: "auto",
    };
    const style_state1_elSoundProfileEditor: React.CSSProperties = {
      pointerEvents: "auto",
    };

    const value_deleteRuleFunctionDeclaration = this.props.ruleId;

    const style_state1_elDeleteRuleFunctionDeclaration: React.CSSProperties = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
      cursor: "pointer",
      pointerEvents: "auto",
    };
    const style_state1_elDeleteRuleFunctionDeclaration_outer: React.CSSProperties =
      {
        display: "none",
      };

    const folderSoundMenu = this.props.foldersProvider.foldersByParentId["root"]
      .sort((x: Folder, y: Folder) => {
        if (x.is_global === y.is_global) {
          return x.name < y.name ? -1 : 1;
        }
        return x.is_global ? -1 : 1;
      })
      .map(this.buildSoundFolderDropdown);

    const menu = (
      <Menu onClick={this.pickerValueChanged_picker}>{folderSoundMenu}</Menu>
    );

    const abilityCheckList = [
      { label: "acrobatics", value: "acrobatics" },
      { label: "animal handling", value: "animal handling" },
      { label: "arcana", value: "arcana" },
      { label: "athletics", value: "athletics" },
      { label: "deception", value: "deception" },
      { label: "history", value: "history" },
      { label: "insight", value: "insight" },
      { label: "intimidation", value: "intimidation" },
      { label: "investigation", value: "investigation" },
      { label: "medicine", value: "medicine" },
      { label: "nature", value: "nature" },
      { label: "perception", value: "perception" },
      { label: "performance", value: "performance" },
      { label: "persuasion", value: "persuasion" },
      { label: "religion", value: "religion" },
      { label: "sleight of hand", value: "sleight of hand" },
      { label: "stealth", value: "stealth" },
      { label: "survival", value: "survival" },
    ];

    const savingCheckList = [
      { label: "strength", value: "strength" },
      { label: "dexterity", value: "dexterity" },
      { label: "constitution", value: "constitution" },
      { label: "intelligence", value: "intelligence" },
      { label: "wisdom", value: "wisdom" },
      { label: "charisma", value: "charisma" },
    ];

    return (
      <div className={SPRCStyles.SoundProfileRuleConfig}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRCStyles.state1_elCard}`}
            style={this.styleBackgroundContainer}
          >
            <div className="cardBg" style={this.styleBackground} />
          </div>

          <div
            className={SPRCStyles.state1_elGrayBackground}
            style={style_state1_elGrayBackground_outer}
          >
            <div style={style_state1_elGrayBackground} />
          </div>
        </div>

        <div className={`layoutFlow ${SPRCStyles.layoutFlow}`}>
          <div className={SPRCStyles.state1_elText475640}>
            <div className="baseFont" style={style_state1_elText475640}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={SPRCStyles.state1_elIconButton2}>
            <button
              className="actionFont collapseRuleConfigButton"
              style={style_state1_elIconButton2}
              onClick={this.onClick_collapseRuleConfig}
            />
          </div>

          {(this.props.ruleType === "string" ||
            this.props.ruleType === "numeric") && (
            <div className={SPRCStyles.state1_elNewWeaponName}>
              <input
                className="baseFont newTextRuleInput"
                style={style_state1_elNewWeaponName}
                type="text"
                placeholder={this.props.ruleTypeExample}
                onChange={this.textInputChanged_newRuleName}
                value={this.state.newRuleName}
              />
            </div>
          )}

          {this.props.ruleType === "dropdown" && (
            <div className={SPRCStyles.state1_elinitiativePicker}>
              <Select
                className="baseFont"
                style={style_state1_elinitiativePicker}
                onChange={this.dropDown_newRuleName}
                value={this.state.newRuleName}
              >
                <option value="" hidden>
                  Select
                </option>
                <option value="begin">begin</option>
                <option value="join">join</option>
                <option value="next">next</option>
                <option value="end">end</option>
              </Select>
            </div>
          )}

          {this.props.ruleType === "suggestion" && (
            <div className={SPRCStyles.state1_elinitiativePicker}>
              <AutoComplete
                options={
                  this.props.ruleId === "ability_check_rules"
                    ? abilityCheckList
                    : savingCheckList
                }
                value={this.state.newRuleName}
                style={{ width: 200 }}
                placeholder={this.props.ruleTypeExample}
                onChange={this.Suggestion_Changed_newRuleName}
              />
            </div>
          )}

          <div className={`antd ${SPRCStyles.soundSelectorDropdown}`}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <AntButton
                className="ant-dropdown-link SoundSelector"
                style={{ width: 180, textAlign: "left" }}
                onClick={(e) => e.preventDefault()}
              >
                {(function () {
                  const maybeSound = sortedSounds.find(
                    (maybeItem: Sound) => maybeItem["id"] === selection_picker
                  );
                  return maybeSound === undefined
                    ? "Select Sound"
                    : maybeSound.name;
                })()}
                <DownOutlined className={SPRCStyles.antdDropArrow} />
              </AntButton>
            </Dropdown>
          </div>

          <div className={SPRCStyles.state1_elCreateWeaponRule}>
            <button
              className="actionFont CreateRuleButton"
              style={style_state1_elCreateWeaponRule}
              onClick={this.onClick_state1_elCreateRule}
            />
          </div>

          <div className={SPRCStyles.state1_elSoundProfileEditor}>
            <div style={style_state1_elSoundProfileEditor}>
              <div className="hasNestedComps elList">
                <ul style={style_elList}>
                  {items_list.map((row: any) => {
                    let ruleEnabled = row[1] !== "NoSound";
                    let ruleProps = {
                      itemName: row[0],
                      soundMapping: row[1],
                      ruleId: this.props.ruleId,
                      ruleEnabled: ruleEnabled,
                      soundProfileConfig: config,
                      deleteRuleCallback:
                        this.onClick_state1_elDeleteRuleFunctionDeclaration,
                    };
                    let itemComp = (
                      <SoundRule
                        {...ruleProps}
                        appActions={this.props.appActions}
                        locStrings={this.props.locStrings}
                        soundsProvider={this.props.soundsProvider}
                        foldersProvider={this.props.foldersProvider}
                        soundDropDownContents={folderSoundMenu}
                      />
                    );
                    return <li key={row.key}>{itemComp}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div
            className={SPRCStyles.state1_elDeleteRuleFunctionDeclaration}
            style={style_state1_elDeleteRuleFunctionDeclaration_outer}
          >
            <button
              className="baseFont"
              style={style_state1_elDeleteRuleFunctionDeclaration}
              onClick={this.onClick_state1_elDeleteRuleFunctionDeclaration}
            >
              <div>
                {value_deleteRuleFunctionDeclaration !== undefined ? (
                  value_deleteRuleFunctionDeclaration
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return withTrace(
      SpanName.SOUND_PROFILE_RULE_CONFIG_RENDER,
      () => {
        let page = null;
        switch (this.state.visualStateIndexOverride) {
          default:
          case 0:
            page = this.renderClosed();
            break;
          case 1:
            page = this.renderOpen();
            break;
        }
        return (
          <TracingErrorBoundary
            name={ErrorBoundaryName.SOUND_PROFILE_RULE_CONFIG}
          >
            {page}
          </TracingErrorBoundary>
        );
      },
      {
        ruleId: this.props.ruleId,
        ruleTypeName: this.props.ruleTypeName,
        ruleType: this.props.ruleType,
        visualStateIndexOverride: this.state.visualStateIndexOverride,
        newRuleName: this.state.newRuleName,
        soundPicker: this.state.picker,
      }
    );
  }
}

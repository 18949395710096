// extracted by mini-css-extract-plugin
export var AntDPagePadding = "Sounds2Screen-module--AntDPagePadding--3RL_R";
export var AntdContentBlock = "Sounds2Screen-module--AntdContentBlock--1_eN8";
export var FooterButtons = "Sounds2Screen-module--FooterButtons--3s2d0";
export var FooterStyle = "Sounds2Screen-module--FooterStyle--RiN97";
export var LeftMenuScroll = "Sounds2Screen-module--LeftMenuScroll--1lpRQ";
export var MUITableStyles = "Sounds2Screen-module--MUITableStyles--2khyP";
export var NewSoundButton = "Sounds2Screen-module--NewSoundButton--23EUZ";
export var SecondaryHeader = "Sounds2Screen-module--SecondaryHeader--au4l7";
export var SecondaryHeaderToolBar = "Sounds2Screen-module--SecondaryHeaderToolBar--27381";
export var SiderBackground = "Sounds2Screen-module--SiderBackground--2Ix9o";
export var SiderTitle = "Sounds2Screen-module--SiderTitle--15BFA";
export var SiderToggle = "Sounds2Screen-module--SiderToggle--ZSsIz";
export var SoundFooterSlider = "Sounds2Screen-module--SoundFooterSlider--XMmGY";
export var Sounds2Screen = "Sounds2Screen-module--Sounds2Screen--3-VOT";
export var UsageBar = "Sounds2Screen-module--UsageBar--1iz15";
export var layoutFlow = "Sounds2Screen-module--layoutFlow--1Xq1a";
export var soundFileInput = "Sounds2Screen-module--soundFileInput--2XOrm";
export var soundUploader = "Sounds2Screen-module--soundUploader--5otsd";
export var uploadButton = "Sounds2Screen-module--uploadButton--APh1W";
import React, { Component } from "react";
import { CurrentUserContext } from "./server-state/CurrentUserContext";
import * as S2SStyles from "./styles/Sounds2Screen.module.css";
import * as AdminPageClasses from "./styles/AdminPage.module.css";
//UI framework component imports
import { Select } from "antd";
import Button from "muicss/lib/react/button";
import Container from "muicss/lib/react/container";
import Panel from "muicss/lib/react/panel";
import BardBotLogo from "./images/favicon_128px.png";
function RedirectHome() {
  if (typeof window !== `undefined`) {
    window.location.replace("/");
  }

  return <React.Fragment></React.Fragment>;
}

class AdminPage extends Component {
  //Properties used by this component:
  //appActions, deviceInfo, profileProvider
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: null,
      selectedGuild: null,
      newGuildID: "",
      toUpdateGuildBool: "",
      updatedStorageBytes: 0,
    };
  }

  user_Picker_Change = (event) => {
    this.setState({
      selectedUser: {
        ...this.props.adminProvider.usersByID[event],
      },
    });
  };
  Guild_Picker_Change = (event) => {
    this.setState({
      selectedGuild: {
        ...this.props.adminProvider.guildsByID[event],
      },
    });
  };

  textInputChanged_Storage_Limit = (event) => {
    this.setState({ updatedStorageBytes: parseInt(event.target.value) });
  };
  textInputChanged_GuildID_New = (event) => {
    this.setState({ newGuildID: event.target.value.trim() });
  };
  textInputChanged_Guild_CustomProfilesAllowed_Update = (event) => {
    this.setState({ toUpdateGuildBool: event.target.value.trim() });
  };

  handleUpdateStorageClick = (ev) => {
    if (
      isNaN(this.state.updatedStorageBytes) ||
      this.state.updatedStorageBytes <= 1000000
    ) {
      window.alert("Must be number greater than 1000000.");
      this.setState({ updatedStorageBytes: 0 });
    } else if (this.state.selectedUser === null) {
      window.alert("Must have user selected!");
    } else if (
      this.state.updatedStorageBytes <
      this.state.selectedUser.storage_limit_bytes
    ) {
      if (
        window.confirm(
          `You want to lower the storage of: ${this.state.selectedUser.discord_username}?`
        )
      ) {
        this.updateStorageLimit();
      }
    } else {
      this.updateStorageLimit();
    }
  };
  handleCreateGuildClick = (ev) => {
    if (isNaN(this.state.newGuildID) || this.state.newGuildID === "") {
      window.alert("Must be Number!");
    } else {
      this.createGuild();
    }
  };
  handleDeleteGuildClick = (ev) => {
    if (
      window.confirm(
        `Are you sure you want to delete guild: ${this.state.selectedGuild.id}?`
      )
    ) {
      this.deleteGuild();
    }
  };
  handleUpdateGuildClick = (ev) => {
    if (this.state.selectedGuild === null) {
      window.alert("Must have guild selected!");
    } else if (this.state.toUpdateGuildBool === "true") {
      this.setState({ toUpdateGuildBool: true });
      this.updateGuild();
    } else if (this.state.toUpdateGuildBool === "false") {
      this.setState({ toUpdateGuildBool: false });
      this.updateGuild();
    } else {
      window.alert("Must be true or false");
    }
  };

  updateStorageLimit = () => {
    const updatedUser = {
      ...this.state.selectedUser,
      storage_limit_bytes: this.state.updatedStorageBytes,
    };
    this.props.adminProvider.updateStorageLimit(updatedUser);
    this.setState({ updatedStorageBytes: 0 });
    this.setState({ selectedUser: updatedUser });
  };

  createGuild = () => {
    this.props.adminProvider.createGuild({
      discord_guild_id: this.state.newGuildID,
      custom_profiles_enabled: true,
    });
    this.setState({ newGuildID: "" });
  };
  deleteGuild = () => {
    const guildToDelete = { ...this.state.selectedGuild };
    this.props.adminProvider.deleteGuild(guildToDelete);
    this.setState({ selectedGuild: null });
  };
  updateGuild = () => {
    const updatedGuild = {
      ...this.state.selectedGuild,
      custom_profiles_enabled: this.state.toUpdateGuildBool,
    };
    this.props.adminProvider.updateGuild(updatedGuild);
    this.setState({ toUpdateGuildBool: "" });
    this.setState({ selectedGuild: updatedGuild });
  };

  render() {
    const layoutFlowStyle = {};
    const baseStyle = {
      backgroundImage: "url(" + BardBotLogo + ")",
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "100px",
      backgroundColor: "#3b3b3b",
    };
    const PanelStyle = {
      backgroundColor: "rgb(150, 150, 150)",
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "100px",
    };
    const style_Profile_Picker = {
      pointerEvents: "auto",
      overflowX: "clip",
      width: "100%",
    };
    const style_Guild_Picker = {
      marginTop: "10px",
    };
    const style_elButton = {
      display: "block",
      color: "white",
      textAlign: "center",
      cursor: "pointer",
      pointerEvents: "auto",
    };
    const style_elName = {
      display: "block",
      backgroundColor: "white",
      paddingLeft: "1rem",
      boxSizing: "border-box", // ensures padding won't expand element's outer size
      textAlign: "left",
      pointerEvents: "auto",
      height: "35px",
    };
    const update_storage_button = {
      paddingTop: "10px",
    };

    let users = Object.values(this.props.adminProvider.usersByID);
    let guilds = Object.values(this.props.adminProvider.guildsByID);

    return (
      <div className={`AppScreen ${S2SStyles.Sounds2Screen}`} style={baseStyle}>
        {this.context.user && this.context.user.is_admin === false && (
          <RedirectHome />
        )}
        {this.context.user && this.context.user.is_admin && (
          <div
            className={`layoutFlow ${S2SStyles.layoutFlow}`}
            style={layoutFlowStyle}
          >
            <Container>
              <Panel className="Container">
                <div>
                  <h1>ADMIN PAGE</h1>
                </div>
              </Panel>
              <Panel style={PanelStyle}>
                <div className={`${AdminPageClasses.adminPage_Update_Storage}`}>
                  <div>
                    <Select
                      className="baseFont"
                      style={style_Profile_Picker}
                      onChange={this.user_Picker_Change}
                      value={
                        this.state.selectedUser
                          ? this.state.selectedUser.id
                          : ""
                      }
                    >
                      <option value="" hidden>
                        Select User
                      </option>
                      {users.map((user) => {
                        return (
                          <option value={user.id}>
                            {"USER: " +
                              user.discord_username +
                              " Discord ID: " +
                              user.discord_id}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.selectedUser !== null && (
                    <div>
                      <h1>
                        Now Inspecting user:{" "}
                        {this.state.selectedUser.discord_username}
                      </h1>
                      <div>
                        <p> ID: " {this.state.selectedUser.id}</p>
                        <p> Discord ID: {this.state.selectedUser.discord_id}</p>
                        <p>
                          {" "}
                          Discord UserName:{" "}
                          {this.state.selectedUser.discord_username}
                        </p>
                        <p>
                          {" "}
                          Is Admin:{" "}
                          {this.state.selectedUser.is_admin.toString()}
                        </p>
                        <p> Default storage: 1000000 (1MB).</p>
                        <p>
                          {" "}
                          Storage Limit:{" "}
                          {this.state.selectedUser.storage_limit_bytes} ::
                          {this.state.selectedUser.storage_limit_bytes /
                            1000000}{" "}
                          Mbs
                        </p>
                      </div>
                    </div>
                  )}
                  {this.state.selectedUser === null && (
                    <div>
                      <h1>Now Inspecting user: </h1>
                      <div>
                        <p> ID: </p>
                        <p> Discord ID: </p>
                        <p> Discord UserName: </p>
                        <p> Is Admin: </p>
                        <p> Default storage: 1000000 (1MB). </p>
                        <p> Storage Limit: </p>
                      </div>
                    </div>
                  )}
                  <div className="defaultProfileHeader elText">
                    <div className="SoundProfileRow">
                      <p id="displayUserInfo"></p>
                      <div className="elName">
                        <input
                          className="baseFont"
                          style={style_elName}
                          type="number"
                          placeholder="1000000"
                          onChange={this.textInputChanged_Storage_Limit}
                          value={this.state.updatedStorageBytes}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="elButton" style={update_storage_button}>
                    <Button
                      className="actionFont"
                      style={style_elButton}
                      color="accent"
                      onClick={this.handleUpdateStorageClick}
                    >
                      Update Storage
                    </Button>
                  </div>
                </div>
              </Panel>
            </Container>
            <Container>
              <Panel style={PanelStyle}>
                <div>
                  <div className={`${AdminPageClasses.new_Guild_Button}`}>
                    <Button
                      className="actionFont"
                      style={style_elButton}
                      color="accent"
                      onClick={this.handleCreateGuildClick}
                    >
                      Create Guild
                    </Button>
                  </div>
                  <div className={`${AdminPageClasses.Guild_Input_Float_Left}`}>
                    <div className="elName">
                      <input
                        className="baseFont"
                        style={style_elName}
                        type="number"
                        placeholder="New Guild ID"
                        onChange={this.textInputChanged_GuildID_New}
                        value={this.state.newGuildID}
                      />
                    </div>
                  </div>
                </div>

                <div className="guild_Picker">
                  <Select
                    className="baseFont"
                    style={{ ...style_Profile_Picker, ...style_Guild_Picker }}
                    onChange={this.Guild_Picker_Change}
                    value={
                      this.state.selectedGuild
                        ? this.state.selectedGuild.id
                        : "guild id"
                    }
                  >
                    <option value="" hidden>
                      Select Guild
                    </option>
                    {guilds.map((guild) => {
                      return (
                        <option value={guild.id}>
                          {"Guild ID:" +
                            guild.discord_guild_id +
                            "Profiles_enabled:" +
                            guild.custom_profiles_enabled}
                        </option>
                      );
                    })}
                  </Select>
                  {this.state.selectedGuild !== null && (
                    <div>
                      <h1>
                        Now Inspecting Guild:{" "}
                        {this.state.selectedGuild.discord_guild_id}
                      </h1>
                      <div>
                        <p> ID: " {this.state.selectedGuild.id}</p>
                        <p>
                          {" "}
                          Discord Guild ID:{" "}
                          {this.state.selectedGuild.discord_guild_id}
                        </p>
                        <p>
                          {" "}
                          Custom profiles enabled:{" "}
                          {this.state.selectedGuild.custom_profiles_enabled.toString()}
                        </p>
                      </div>
                    </div>
                  )}
                  {this.state.selectedGuild === null && (
                    <div>
                      <h1>Now Inspecting Guild: </h1>
                      <div>
                        <p> ID: </p>
                        <p> Discord Guild ID: </p>
                        <p> Custom profiles enabled: </p>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className={`${AdminPageClasses.Guild_Input_Float_Left}`}>
                    <input
                      className="baseFont"
                      style={style_elName}
                      type="text"
                      placeholder="True or False"
                      onChange={
                        this.textInputChanged_Guild_CustomProfilesAllowed_Update
                      }
                      value={this.state.toUpdateGuildBool}
                    />
                  </div>
                  <div
                    className={`${AdminPageClasses.Guild_Input_Float_Right}`}
                  >
                    <Button
                      className="actionFont"
                      style={style_elButton}
                      color="accent"
                      onClick={this.handleDeleteGuildClick}
                    >
                      Delete Guild
                    </Button>
                  </div>
                  <div className={`${AdminPageClasses.new_Guild_Button}`}>
                    <Button
                      className="actionFont"
                      style={style_elButton}
                      color="accent"
                      onClick={this.handleUpdateGuildClick}
                    >
                      Update Guild
                    </Button>
                  </div>
                </div>
              </Panel>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

AdminPage.contextType = CurrentUserContext;

export default AdminPage;

import { Attributes } from "@opentelemetry/api";
import { SpanName } from "./SpanNames";
import { withTrace as withTraceExternal } from "tracing-utils/tracing";

export function withTrace<T>(
  name: SpanName,
  fn: () => T,
  attributes?: Attributes
): T {
  return withTraceExternal(name, fn, attributes);
}

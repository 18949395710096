import React, { Component } from "react";
import AudioPlayer from "react-h5-audio-player";
import { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import btn_icon_859097 from "../images/btn_icon_859097.png";
import { SortSounds } from "../server-state/util";
import * as SoundRuleStyles from "../styles/SoundRule.module.css";
// UI framework component imports
import Button from "muicss/lib/react/button";
import { Menu, Dropdown, Button as AntButton } from "antd";
import { DownOutlined } from "@ant-design/icons";
export default class SoundRule extends Component {
  // Properties used by this component:
  // soundMapping, itemName, visualStateIndex

  constructor(props) {
    super(props);

    this.state = {
      picker: this.props.soundMapping,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ picker: nextProps.soundMapping });
  }

  handleDelete = (ev) => {
    // Write your script here

    this.props.deleteRuleCallback(this.props.itemName);
  };

  handleSoundSelect = (event) => {
    this.setState({ picker: { Sound: event.key } });

    if (!this.props.ruleId || !this.props.soundProfileConfig) {
      return;
    }

    const config = this.props.soundProfileConfig;
    const currentRule = this.props.itemName;
    var updatedConfig = { ...config };
    updatedConfig[this.props.ruleId][currentRule] = {
      Sound: event.key,
    };

    this.setState({ soundProfileConfig: updatedConfig });
  };

  disableRule = (ev) => {
    this.setState({ ruleEnabled: false });

    if (!this.props.ruleId || !this.props.soundProfileConfig) {
      return;
    }

    const config = this.props.soundProfileConfig;
    const currentRule = this.props.itemName;
    var updatedConfig = { ...config };
    updatedConfig[this.props.ruleId][currentRule] = "NoSound";

    this.setState({ soundProfileConfig: updatedConfig });
  };

  deleteButtonStyle = {
    display: "block",
    textTransform: "uppercase",
    backgroundImage: "url(" + btn_icon_859097 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "85.184%",
    backgroundPosition: "50% 0%",
    color: "(null)",
    textAlign: "left",
    backgroundColor: "transparent",
    cursor: "pointer",
    pointerEvents: "auto",
  };

  textStyle = {
    color: "rgba(0, 0, 0, 0.8500)",
    textAlign: "left",
  };

  toggleButtonStyle = {
    display: "block",
    color: "white",
    textAlign: "center",
    cursor: "pointer",
    pointerEvents: "auto",
  };

  DropDownSizeLimitStyle = {
    minWidth: "120px",
    maxHeight: "250px",
    overflow: "auto",
  };

  renderRuleEnabled() {
    const soundId = this.state.picker.Sound || null;
    const audioSrcUrl = soundId
      ? `/api/v1/my-sounds/sound-data/${soundId}`
      : null;
    const style_state0_elSoundPlayer = {
      pointerEvents: "auto",
    };
    let transformPropValue_picker = (input) => {
      // This function modifies the value for property 'selectionValueName'.
      // There is a variable named 'input' that provides the property value.
      //

      // input = { Sound: "21281f6c-616d-41f0-9b4d-db8d2b8bc392" }
      // or input = "NoSound"

      if (!input || input === "NoSound") {
        return null;
      } else {
        return input["Sound"];
      }
    };

    let selectedSoundId = this.state.picker;
    // Source datasheet and selected data column for picker element 'picker'
    const sortedSounds = SortSounds(
      Object.values(this.props.soundsProvider.userSounds).concat(
        Object.values(this.props.soundsProvider.defaultSounds)
      )
    );

    selectedSoundId = transformPropValue_picker(selectedSoundId);

    const value_text = this.props.itemName;

    const menu = (
      <Menu onClick={this.handleSoundSelect}>
        {this.props.soundDropDownContents}
      </Menu>
    );

    return (
      <div className={SoundRuleStyles.SoundRule}>
        <div className={SoundRuleStyles.foreground}>
          <div className={SoundRuleStyles.state0_elSpacer583816} />
          <button
            className={`actionFont ${SoundRuleStyles.state0_elIconButton}`}
            style={this.deleteButtonStyle}
            onClick={this.handleDelete}
          />
          <div
            className={SoundRuleStyles.state0_elSoundPlayer}
            style={style_state0_elSoundPlayer}
          >
            <AudioPlayer
              src={audioSrcUrl}
              autoPlay={false}
              autoPlayAfterSrcChange={false}
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
              showJumpControls={false}
              style={{ width: "250px", padding: "0px", "margin-top": "5px" }}
              customVolumeControls={[]}
              customAdditionalControls={[]}
              layout="horizontal-reverse"
            />
          </div>
          <div className={`antd ${SoundRuleStyles.soundDropdownSelector}`}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <AntButton
                className="ant-dropdown-link"
                style={{ width: 180, textAlign: "left" }}
                onClick={(e) => e.preventDefault()}
              >
                {(function () {
                  const maybeSound = sortedSounds.find(
                    (maybeItem) => maybeItem["id"] === selectedSoundId
                  );
                  return maybeSound === undefined ? "Error" : maybeSound.name;
                })()}
                <DownOutlined className={SoundRuleStyles.antdDropArrow} />
              </AntButton>
            </Dropdown>
          </div>
          <Button
            className={`actionFont DisableRuleButton ${SoundRuleStyles.state0_elButton}`}
            style={this.toggleButtonStyle}
            color="accent"
            onClick={this.disableRule}
          >
            Disable
          </Button>
          <div
            className={`baseFont ${SoundRuleStyles.state0_elText564202}`}
            style={this.textStyle}
          >
            <div>
              {value_text !== undefined ? (
                value_text
              ) : (
                <span className="propValueMissing">
                  Oops something is missing.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  enableRule = (ev) => {
    this.setState({ ruleEnabled: true });

    if (!this.props.ruleId || !this.props.soundProfileConfig) {
      return;
    }

    const config = this.props.soundProfileConfig;
    const currentRule = this.props.itemName;
    var updatedConfig = { ...config };
    updatedConfig[this.props.ruleId][currentRule] = this.state.picker;

    this.setState({ soundProfileConfig: updatedConfig });
  };

  renderRuleDisabled() {
    const value_text = this.props.itemName;

    return (
      <div className={SoundRuleStyles.SoundRule}>
        <div className={SoundRuleStyles.foreground}>
          <div className={SoundRuleStyles.state1_elSpacer583816} />
          <button
            className={`actionFont ${SoundRuleStyles.state1_elIconButton}`}
            style={this.deleteButtonStyle}
            onClick={this.handleDelete}
          />
          <div
            className={`baseFont ${SoundRuleStyles.state1_elText2}`}
            style={this.textStyle}
          >
            <div>No sound will be played</div>
          </div>
          <Button
            className={`actionFont ${SoundRuleStyles.state1_elButton}`}
            style={this.toggleButtonStyle}
            color="accent"
            onClick={this.enableRule}
          >
            Enable
          </Button>
          <div
            className={`baseFont ${SoundRuleStyles.state1_elText564202}`}
            style={this.textStyle}
          >
            <div>
              {value_text !== undefined ? (
                value_text
              ) : (
                <span className="propValueMissing">
                  Oops something is missing.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const ruleEnabled =
      this.state && this.state.ruleEnabled !== undefined
        ? this.state.ruleEnabled
        : this.props.ruleEnabled;

    if (ruleEnabled) {
      return this.renderRuleEnabled();
    } else {
      return this.renderRuleDisabled();
    }
  }
}

import React from "react";
import { ErrorBoundaryName } from "./SpanNames";
import { TracingErrorBoundary as TracingErrorBoundaryExt } from "tracing-utils/TracingErrorBoundary";

interface Props {
  name: ErrorBoundaryName;
}

export const TracingErrorBoundary: React.FunctionComponent<Props> = (props) => {
  return (
    <TracingErrorBoundaryExt name={props.name}>
      {props.children}
    </TracingErrorBoundaryExt>
  );
};

export enum ErrorBoundaryName {
  APP = "appErrorBoundary",
  SOUND_PROFILE_EDITOR = "soundProfileEditorErrorBoundary",
  SOUND_PROFILE_ROW = "soundProfileRowErrorBoundary",
  IMMUTABLE_SOUND_PROFILE_ROW = "soundProfileRowErrorBoundary",
  SOUND_PROFILE_RULE_CONFIG = "soundProfileRuleConfigErrorBoundary",
  IMMUTABLE_SOUND_PROFILE_RULE_CONFIG = "soundProfileRuleConfigErrorBoundary",

  SOUND_MANAGER = "soundManagerErrorBoundary",
  SOUND_FOOTER = "soundFooterErrorBoundary",
  SOUND_UPLOAD_MODAL = "soundUploadModalErrorBoundary",
}

export enum SpanName {
  SOUND_PROFILES_PAGE_RENDER = "soundProfilesPageRender",
  IMMUTABLE_SOUND_PROFILE_ROW_RENDER = "immutableSoundProfileRowRender",
  SOUND_PROFILE_ROW_RENDER = "soundProfileRowRender",
  IMMUTABLE_SOUND_PROFILE_RULE_CONFIG_RENDER = "immutableSoundProfileRuleConfigRender",
  SOUND_PROFILE_RULE_CONFIG_RENDER = "soundProfileRuleConfigRender",

  SOUND_MANAGER_RENDER = "soundManagerRender",
  SOUND_FOOTER_RENDER = "soundFooterRender",
  SOUND_UPLOAD_MODAL = "soundUploadModalRender",
}

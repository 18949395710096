import React, { Component } from "react";
import * as S2SStyles from "./styles/Sounds2Screen.module.css";
import {
  Button,
  Layout,
  Space,
  Row,
  Col,
  Slider,
  Popover,
  Tooltip,
} from "antd";
import { FormControlLabel, Switch } from "@mui/material";
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  SoundOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Sound } from "./server-state/SoundsProvider";
import { PlayToDiscord as PlayToDiscordHandler } from "./PlayToDiscord";
import { withTrace } from "./tracing/tracing";
import { ErrorBoundaryName, SpanName } from "./tracing/SpanNames";
import { TracingErrorBoundary } from "./tracing/TracingErrorBoundary";

export interface Props {
  soundData: Sound | null;
  isAutoPlay: boolean;
  soundIndex: number | null;
  handleNextButton: (number: number | null) => void;
  handleBackButton: (number: number | null) => void;
  toggleAutoPlay: () => void;
  windowSize: number;
}

export interface State {
  play: boolean;
  showOptionsMenu: boolean;
  currentSoundTime: number;
  isPlayingToDiscord: boolean;
}

class SoundFooter extends Component<Props, State> {
  audio: HTMLAudioElement;
  Timer: NodeJS.Timer | null;
  lastPlayState: boolean;
  playToDiscordHandler: PlayToDiscordHandler;
  initialVolume: string | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      play: false,
      showOptionsMenu: false,
      currentSoundTime: 0,
      isPlayingToDiscord: false,
    };
    this.audio = new Audio();
    this.initialVolume = localStorage.getItem("SoundVolume");
    this.audio.volume =
      this.initialVolume == null ||
      Number(this.initialVolume) > 1 ||
      Number(this.initialVolume) < 0
        ? 1
        : Number(this.initialVolume);
    this.Timer = null;
    this.lastPlayState = false;
    this.playToDiscordHandler = new PlayToDiscordHandler();
  }

  componentDidMount() {
    this.audio.addEventListener("ended", () => {
      this.setState({ play: false });
      clearInterval(this.Timer!);
    });
  }

  componentWillUnmount() {
    this.audio.removeEventListener("ended", () => {
      this.setState({ play: false });
      clearInterval(this.Timer!);
    });
  }

  componentDidUpdate() {}

  setAudioSrcUrl = () => {
    if (
      this.props.soundData !== null &&
      !this.audio.src.endsWith(this.props.soundData.id)
    ) {
      this.audio.src = `/api/v1/my-sounds/sound-data/${this.props.soundData.id}`;
      this.setState({ play: false });
      if (this.props.isAutoPlay) {
        this.setState({ play: true }, () => {
          this.audio.play();
          this.songSliderUpdater();
        });
      }
    }
  };

  toggleOptionsMenu = () => {
    this.setState({ showOptionsMenu: !this.state.showOptionsMenu });
  };

  togglePlay = () => {
    this.setState({ play: true }, () => {
      this.audio.play();
      this.songSliderUpdater();
    });
  };

  togglePause = () => {
    this.setState({ play: false }, () => {
      this.audio.pause();
    });
  };

  playToDiscord = () => {
    if (this.props.soundData !== null) {
      this.setState({ isPlayingToDiscord: true });
      this.playToDiscordHandler.PlayToDiscord(this.props.soundData);
      setTimeout(() => {
        //Start the timer
        this.setState({ isPlayingToDiscord: false });
      }, this.props.soundData.length_in_seconds * 1000);
    }
  };

  songSliderUpdater = () => {
    // Clear any timers already running
    if (this.Timer !== null) {
      clearInterval(this.Timer);
    }
    this.Timer = setInterval(() => {
      this.setState({ currentSoundTime: this.audio.currentTime });
    }, 100);
  };

  render() {
    this.setAudioSrcUrl();
    const optionsPopOver = this.buildPlaybackSettingsPopover();

    return withTrace(SpanName.SOUND_FOOTER_RENDER, () => (
      <TracingErrorBoundary name={ErrorBoundaryName.SOUND_FOOTER}>
        <Layout.Footer
          className={S2SStyles.FooterStyle}
          style={{ textAlign: "center" }}
        >
          <Row>
            <Col span={this.props.windowSize > 480 ? 4 : 0}>
              <h4 style={{ color: "beige" }}>
                {this.props.soundData?.name ?? ""}
              </h4>
            </Col>

            <Col span={this.props.windowSize > 480 ? 17 : 21}>
              {this.buildPlaybackControls()}
              {this.buildPlaybackProgressBar()}
            </Col>
            <Col span={3}>{optionsPopOver}</Col>
          </Row>
        </Layout.Footer>
      </TracingErrorBoundary>
    ));
  }

  private buildPlaybackProgressBar() {
    return (
      <div
        role="none"
        onMouseDown={() => {
          this.lastPlayState = this.state.play;
        }}
      >
        <Slider
          className={S2SStyles.SoundFooterSlider}
          value={this.audio.currentTime}
          tooltipVisible={false}
          step={
            this.props.soundData?.length_in_seconds
              ? this.props.soundData?.length_in_seconds / 20
              : 0.1
          }
          min={0}
          max={this.props.soundData?.length_in_seconds}
          onChange={(e) => {
            this.audio.currentTime = e;
            this.togglePause();
          }}
          onAfterChange={(e) => {
            if (this.lastPlayState) {
              this.togglePlay();
            }
          }}
        />
      </div>
    );
  }

  private buildPlaybackControls(): React.ReactNode {
    return this.props.windowSize <= 760 ? (
      <Space size="small">
        {this.buildBackButton()}
        {this.state.play ? this.buildPauseButton() : this.buildPlayButton()}
        {this.buildForwardButton()}
        {this.buildPlayToDiscordButton()}
      </Space>
    ) : (
      <>
        <Space size="large">
          {this.buildBackButton()}
          {this.state.play ? this.buildPauseButton() : this.buildPlayButton()}
          {this.buildForwardButton()}
        </Space>
        {this.buildPlayToDiscordButton()}
      </>
    );
  }

  private buildPlaybackSettingsPopover() {
    return (
      <Popover
        content={
          <div style={{ padding: "15px" }}>
            <div>
              <SoundOutlined /> Volume
            </div>
            <div>
              <Slider
                min={0}
                max={100}
                defaultValue={this.audio.volume * 100}
                onChange={(e) => {
                  this.audio.volume = e / 100;
                  localStorage.setItem(
                    "SoundVolume",
                    String(this.audio.volume)
                  );
                }}
              />
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.isAutoPlay}
                  onChange={() => this.props.toggleAutoPlay()}
                />
              }
              label="Auto Play"
            />
          </div>
        }
        title="Settings"
        trigger="click"
        visible={this.state.showOptionsMenu}
        onVisibleChange={this.toggleOptionsMenu}
      >
        <button
          className={`${S2SStyles.FooterButtons}`}
          onClick={(e) => e.preventDefault()}
        >
          <SettingOutlined />
        </button>
      </Popover>
    );
  }

  private buildPlayToDiscordButton() {
    return (
      <Tooltip
        title="Play sound to Discord when Bard is in your voice channel!"
        placement="top"
        mouseEnterDelay={2}
      >
        <Button
          icon={<PlayCircleOutlined />}
          className={`playToDiscord`}
          style={{
            background: "#191919",
            color: "beige",
            float: "right",
            padding: "revert",
          }}
          loading={this.state.isPlayingToDiscord}
          onClick={() => this.playToDiscord()}
          aria-label="Play to Discord"
        >
          Discord
        </Button>
      </Tooltip>
    );
  }

  private buildPlayButton() {
    return (
      <button
        className={`play ${S2SStyles.FooterButtons}`}
        onClick={this.togglePlay}
        aria-label="Play"
      >
        <PlayCircleOutlined />
      </button>
    );
  }

  private buildPauseButton() {
    return (
      <button
        className={`pause ${S2SStyles.FooterButtons}`}
        onClick={this.togglePause}
        aria-label="Pause"
      >
        <PauseCircleOutlined />
      </button>
    );
  }

  private buildForwardButton() {
    return (
      <button
        className={`${S2SStyles.FooterButtons}`}
        onClick={() => this.props.handleNextButton(this.props.soundIndex)}
      >
        <RightCircleOutlined />
      </button>
    );
  }

  private buildBackButton() {
    return (
      <button
        className={`${S2SStyles.FooterButtons}`}
        onClick={() => this.props.handleBackButton(this.props.soundIndex)}
      >
        <LeftCircleOutlined />
      </button>
    );
  }
}

export default SoundFooter;

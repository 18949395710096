import React, { Component } from "react";

export default class WheelOfFrustration extends Component {
  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.script = null;
    this.state = {};
  }

  componentDidMount() {
    const script = document.createElement("script");
    this.script = script;

    script.src = "/haxe-games/wheel-of-frustration.js";
    script.async = true;

    document.body.appendChild(script);
  }

  componentWillUnmount() {
    document.body.removeChild(this.script);
  }

  componentDidUpdate() {}

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (
      this.props.transitionId &&
      this.props.transitionId.length > 0 &&
      this.props.atTopOfScreenStack &&
      this.props.transitionForward
    ) {
      baseStyle.animation = "0.25s ease-in-out " + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = "100vh";
      layoutFlowStyle.overflow = "hidden";
    }

    const style_elBackground = {
      width: "100%",
      height: "100%",
    };
    const style_elBackground_outer = {
      backgroundColor: "#f6f6f6",
    };

    return (
      <div className="AppScreen GamePage" style={baseStyle}>
        <div className="background">
          <div
            className="containerMinHeight elBackground"
            style={style_elBackground_outer}
          >
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>

        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>

          <div className="gameContainer">
            <canvas id="webgl" width="640" height="480"></canvas>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import { SpanStatusCode, trace } from "@opentelemetry/api";

interface Props {
  name: string;
}

interface State {
  hasError: boolean;
}

export class TracingErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const activeSpan = trace.getActiveSpan();
    if (typeof activeSpan !== "undefined") {
      activeSpan.addEvent(`${this.props.name} error stack`, {
        componentStack: errorInfo.componentStack,
      });
      activeSpan.recordException(error);
      activeSpan.setStatus({
        message: `unexpected error caught by ${this.props.name}`,
        code: SpanStatusCode.ERROR,
      });
    }
  }

  // Do we want to wrap this render in a trace as well?
  render() {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <h1>
          Something went wrong. Let us know in the <a href="https://discord.gg/XASDPeB">discord</a>.
        </h1>
      );
    }
    return this.props.children;
  }
}

import React, { Component } from "react";
import { withTrace } from "../tracing/tracing";
import { SpanName } from "../tracing/SpanNames";
import { TracingErrorBoundary } from "../tracing/TracingErrorBoundary";
import { ErrorBoundaryName } from "../tracing/SpanNames";
import ImmutableSoundProfileRuleConfig from "./ImmutableSoundProfileRuleConfig";

import btn_icon_623697 from "../images/btn_icon_623697.png";
import btn_icon_413415 from "../images/btn_icon_413415.png";
import btn_icon_186602 from "../images/btn_icon_186602.png";
import * as SPRStyles from "../styles/SoundProfileRow.module.css";
// UI framework component imports

export default class ImmutableSoundProfileRow extends Component {
  // Properties used by this component:
  // dataSheetRow, soundProfileConfig, ruleId, ruleTypeName, soundMapping, itemName, visualStateIndex

  constructor(props) {
    super(props);

    this.state = {
      state1_elSaveSuccessful_visible: false,
      visualStateIndexOverride: 0,
    };
  }

  // --- Functions for component state index 0 (1 of 2) ---

  openProfile = (ev) => {
    this.setState({ visualStateIndexOverride: 1 });
  };

  renderState0() {
    const style_state0_elCard217157 = {
      width: "100%",
      height: "100%",
    };
    const style_state0_elCard217157_outer = {
      backgroundColor: "white",
      filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
      overflow: "visible",
    };

    const style_state0_elRectangle130967 = {
      background: "rgba(236, 236, 236, 1.000)",
      display: "none",
    };

    const value_text = this.props.soundProfile
      ? this.props.soundProfile.name
      : "";

    const style_state0_elText177450 = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
      fontWeight: "bold",
    };

    const style_state0_elIconButton2 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_623697 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "129.499%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    return (
      <div className={SPRStyles.SoundProfileRow}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRStyles.state0_elCard217157}`}
            style={style_state0_elCard217157_outer}
          >
            <div className="cardBg" style={style_state0_elCard217157} />
          </div>

          <div
            className={SPRStyles.state0_elRectangle130967}
            style={style_state0_elRectangle130967}
          />
        </div>

        <div className={`layoutFlow ${SPRStyles.layoutFlow}`}>
          <div className={SPRStyles.state0_elText177450}>
            <div className="baseFont" style={style_state0_elText177450}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={`${SPRStyles.flowRow}`}>
            <div className={SPRStyles.state0_elIconButton2}>
              <button
                title="Expand Profile"
                className="actionFont"
                style={style_state0_elIconButton2}
                onClick={this.openProfile}
              />
            </div>
          </div>

          <div className={SPRStyles.state0_elSpacer}>
            <div />
          </div>
        </div>
      </div>
    );
  }

  // --- Functions for component state index 1 (2 of 2) ---

  closeProfile = (ev) => {
    this.setState({ visualStateIndexOverride: 0 });
  };

  onClick_state1_elIconButton22783 = (ev) => {
    this.handleDeleteSoundProfile();
  };

  renderState1() {
    const style_state1_elCard217157 = {
      width: "100%",
      height: "100%",
    };
    const style_state1_elCard217157_outer = {
      backgroundColor: "white",
      filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
      overflow: "visible",
    };

    const style_state1_elRectangle130967 = {
      background: "rgba(236, 236, 236, 1.000)",
      display: "none",
    };

    const value_text = this.props.soundProfile
      ? this.props.soundProfile.name
      : "";

    const style_state1_elText177450 = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
      fontWeight: "bold",
    };

    const style_state1_elIconButton2 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_413415 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "150.614%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    const style_state1_elSaveSuccessful = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_186602 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "90.122%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
    };
    const state1_elSaveSuccessful = this.state
      .state1_elSaveSuccessful_visible ? (
      <div className="state1_elSaveSuccessful">
        <div className="actionFont" style={style_state1_elSaveSuccessful} />
      </div>
    ) : null;

    return (
      <div className={SPRStyles.SoundProfileRow}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRStyles.state1_elCard217157}`}
            style={style_state1_elCard217157_outer}
          >
            <div className="cardBg" style={style_state1_elCard217157} />
          </div>

          <div
            className={SPRStyles.state1_elRectangle130967}
            style={style_state1_elRectangle130967}
          />
        </div>

        <div className={`layoutFlow ${SPRStyles.layoutFlow}`}>
          <div className={SPRStyles.state1_elText177450}>
            <div className="baseFont" style={style_state1_elText177450}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={SPRStyles.flowRow}>
            <div className={SPRStyles.state1_elIconButton2}>
              <button
                title="Collapse Profile"
                className="actionFont"
                style={style_state1_elIconButton2}
                onClick={this.closeProfile}
              />
            </div>

            <div className={SPRStyles.flowRow}>{state1_elSaveSuccessful}</div>
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elAttackRules}
            `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"attack_rules"}
                ruleTypeName={"Attacks"}
                ref={(el) => (this._state1_elAttackRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"spell_rules"}
                ruleTypeName={"Spells"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"attack_role_rules"}
                ruleTypeName={"Attack Rolls"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"ability_check_roll_rules"}
                ruleTypeName={"Ability Check Rolls"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"ability_check_rules"}
                ruleTypeName={"Ability Check"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"saving_throw_roll_rules"}
                ruleTypeName={"Saving Throw Rolls"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"saving_throw_rules"}
                ruleTypeName={"Saving Throw"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"initiative_rules"}
                ruleTypeName={"Initiative"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>

          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <ImmutableSoundProfileRuleConfig
                soundProfileConfig={
                  this.props.soundProfile ? this.props.soundProfile.config : ""
                }
                ruleId={"custom_sound_rules"}
                ruleTypeName={"Custom Sounds"}
                ref={(el) => (this._state1_elSpellRules = el)}
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>

          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return withTrace(
      SpanName.IMMUTABLE_SOUND_PROFILE_ROW_RENDER,
      () => {
        switch (parseInt(this.state.visualStateIndexOverride, 10)) {
          default:
          case 0:
            return (
              <TracingErrorBoundary
                name={ErrorBoundaryName.IMMUTABLE_SOUND_PROFILE_ROW}
              >
                {this.renderState0()}
              </TracingErrorBoundary>
            );
          case 1:
            return (
              <TracingErrorBoundary
                name={ErrorBoundaryName.IMMUTABLE_SOUND_PROFILE_ROW}
              >
                {this.renderState1()}
              </TracingErrorBoundary>
            );
        }
      },
      {
        "soundProfile.name": this.props.soundProfile.name,
        visualStateIndexOverride: this.state.visualStateIndexOverride,
      }
    );
  }
}

import { alertErrorHelper } from "./server-state/util";

import { Sound } from "./server-state/SoundsProvider";

export class PlayToDiscord {
  url = "/api/v1/my-sounds";

  PlayToDiscord = (userSound: Sound) => {
    const opts = {
      method: "POST",
    };
    fetch(this.url + `/${userSound.id}/play-via-discord`, opts).then((resp) =>
      redirectOn401(resp, (resp: Response) => {
        if (resp.status >= 400) {
          alertErrorHelper(`PlayingToDiscord: ${userSound.name}`, resp);
          throw new Error("Server Error: " + resp.status);
        }
      })
    );
  };
}

export default PlayToDiscord;

import React, { Component } from "react";
import btn_icon_623697 from "../images/btn_icon_623697.png";
import btn_icon_22783 from "../images/btn_icon_22783.png";
import btn_icon_413415 from "../images/btn_icon_413415.png";
import btn_icon_186602 from "../images/btn_icon_186602.png";
import SoundProfileRuleConfig from "./SoundProfileRuleConfig";
import * as SPRStyles from "../styles/SoundProfileRow.module.css";
// UI framework component imports
import Checkbox from "muicss/lib/react/checkbox";
import Button from "muicss/lib/react/button";
import { CurrentUserContext } from "../server-state/CurrentUserContext";
import { withTrace } from "../tracing/tracing";
import { ErrorBoundaryName, SpanName } from "../tracing/SpanNames";
import { TracingErrorBoundary } from "../tracing/TracingErrorBoundary";

class SoundProfileRow extends Component {
  // Properties used by this component:
  // currentUser, updateCurrentUser, dataSheetRow, visualStateIndex

  constructor(props, context) {
    super(props, context);

    this.soundProfileConfig = { ...this.props.soundProfile.config };

    this.state = {
      active:
        this.context && this.context.user
          ? this.context.user.active_sound_profile ===
            this.props.soundProfile.id
          : false,
      state1_elSaveSuccessful_visible: false,
      visualStateIndexOverride: 0,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  // --- Functions for component state index 0 (1 of 2) ---

  expandProfile = (ev) => {
    this.setState({ visualStateIndexOverride: 1 });
  };

  handleDeleteSoundProfile = () => {
    let shouldDelete = window.confirm(
      `Are you sure you want to delete the ${this.props.soundProfile.name} sound profile?`
    );

    if (shouldDelete) {
      this.props.profileProvider.deleteSoundProfile(this.props.soundProfile);
    }
  };

  handleXButtonClick = (ev) => {
    this.handleDeleteSoundProfile();
  };

  checkboxChanged_active = (event) => {
    // checked here implies it's going from unchecked to checked
    var updatedUser = {};
    if (event.target.checked) {
      this.setState({ active: "true" });
      updatedUser = {
        ...this.context.user,
        active_sound_profile: this.props.soundProfile.id,
      };
    } else {
      this.setState({ active: "false" });
      updatedUser = { ...this.context.user, active_sound_profile: null };
    }

    this.context.updateCurrentUser(updatedUser);
  };

  renderState0() {
    const style_state0_elCard217157 = {
      width: "100%",
      height: "100%",
    };
    const style_state0_elCard217157_outer = {
      backgroundColor: "white",
      filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
      overflow: "visible",
    };

    const style_state0_elRectangle130967 = {
      background: "rgba(236, 236, 236, 1.000)",
      display: "none",
    };

    const value_text = this.props.soundProfile
      ? this.props.soundProfile.name
      : "";

    const style_sound_profile_name0 = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
      fontWeight: "bold",
      overflow: "hidden",
    };

    const style_state0_elIconButton2 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_623697 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "129.499%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    const style_state0_elIconButton22783 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_22783 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "140.000%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    let checked_active = this.context.user
      ? this.context.user.active_sound_profile === this.props.soundProfile.id
      : false;

    const style_state0_elActive853462 = {
      pointerEvents: "auto",
    };

    return (
      <div className={`${SPRStyles.SoundProfileRow} SoundProfileRow`}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRStyles.state0_elCard217157}`}
            style={style_state0_elCard217157_outer}
          >
            <div className="cardBg" style={style_state0_elCard217157} />
          </div>

          <divflowRow
            className={SPRStyles.state0_elRectangle130967}
            style={style_state0_elRectangle130967}
          />
        </div>

        <div className={`layoutFlow ${SPRStyles.layoutFlow}`}>
          <div className={SPRStyles.state0_elText177450}>
            <div className="baseFont" style={style_sound_profile_name0}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div
            className={`flowRow_SoundProfileRow_state0_elIconButton2_623697 ${SPRStyles.flowRow}`}
          >
            <div className={SPRStyles.state0_elIconButton2}>
              <button
                className="actionFont expandProfileButton"
                style={style_state0_elIconButton2}
                onClick={this.expandProfile}
              />
            </div>

            <div
              className={`deleteSoundProfileContainer ${SPRStyles.state0_elIconButton22783}`}
            >
              <button
                className="actionFont deleteSoundProfileButton"
                style={style_state0_elIconButton22783}
                onClick={this.handleXButtonClick}
              />
            </div>

            <div className={SPRStyles.state0_elActive853462}>
              <Checkbox
                className="baseFont soundProfileActiveCheckbox"
                style={style_state0_elActive853462}
                label={"Active"}
                onChange={this.checkboxChanged_active}
                checked={
                  checked_active === "true" ||
                  checked_active === true ||
                  checked_active === "1"
                }
              />
            </div>
          </div>
          <div className={SPRStyles.state0_elSpacer}>
            <div />
          </div>
        </div>
      </div>
    );
  }

  // --- Functions for component state index 1 (2 of 2) ---

  collapseProfile = (ev) => {
    this.setState({ visualStateIndexOverride: 0 });
  };

  onClick_state1_elSave = (ev) => {
    this.saveProfile();

    this.setState({ state1_elSaveSuccessful_visible: true });

    setTimeout(() => {
      this.setState({ state1_elSaveSuccessful_visible: false });
    }, 2000);
  };

  saveProfile = () => {
    this.props.profileProvider.updateSoundProfile({
      ...this.props.soundProfile,
      config: this.soundProfileConfig,
    });
  };

  renderState1() {
    const style_state1_elCard217157 = {
      width: "100%",
      height: "100%",
    };
    const style_state1_elCard217157_outer = {
      backgroundColor: "white",
      filter: "drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))",
      overflow: "visible",
    };

    const style_state1_elRectangle130967 = {
      background: "rgba(236, 236, 236, 1.000)",
      display: "none",
    };

    const value_text = this.props.soundProfile
      ? this.props.soundProfile.name
      : "";

    const style_sound_profile_name1 = {
      color: "rgba(0, 0, 0, 0.8500)",
      textAlign: "left",
      fontWeight: "bold",
      overflow: "hidden",
    };

    const style_state1_elIconButton2 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_413415 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "150.614%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    const style_state1_elIconButton22783 = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_22783 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "140.000%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    let checked_active = this.context.user
      ? this.context.user.active_sound_profile === this.props.soundProfile.id
      : false;

    const style_state1_elActive853462 = {
      pointerEvents: "auto",
    };

    const style_state1_elSave = {
      display: "block",
      color: "white",
      textAlign: "center",
      cursor: "pointer",
      pointerEvents: "auto",
    };

    const style_state1_elSaveSuccessful = {
      display: "block",
      textTransform: "uppercase",
      backgroundImage: "url(" + btn_icon_186602 + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "90.122%",
      backgroundPosition: "50% 0%",
      color: "(null)",
      textAlign: "left",
      backgroundColor: "transparent",
    };
    const state1_elSaveSuccessful = this.state
      .state1_elSaveSuccessful_visible ? (
      <div className={SPRStyles.state1_elSaveSuccessful}>
        <button className="actionFont" style={style_state1_elSaveSuccessful} />
      </div>
    ) : null;

    return (
      <div className={`${SPRStyles.SoundProfileRow} SoundProfileRow`}>
        <div className="background">
          <div
            className={`containerMinHeight ${SPRStyles.state1_elCard217157}`}
            style={style_state1_elCard217157_outer}
          >
            <div className="cardBg" style={style_state1_elCard217157} />
          </div>

          <div
            className={SPRStyles.state1_elRectangle130967}
            style={style_state1_elRectangle130967}
          />
        </div>

        <div className={`layoutFlow ${SPRStyles.layoutFlow}`}>
          <div className={SPRStyles.state1_elText177450}>
            <div className="baseFont" style={style_sound_profile_name1}>
              <div>
                {value_text !== undefined ? (
                  value_text
                ) : (
                  <span className="propValueMissing">
                    Oops something is missing.
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={SPRStyles.flowRow}>
            <div className={SPRStyles.state1_elIconButton2}>
              <button
                className="actionFont collapseProfileButton"
                style={style_state1_elIconButton2}
                onClick={this.collapseProfile}
              />
            </div>

            <div className={SPRStyles.state1_elIconButton22783}>
              <button
                className="actionFont deleteSoundProfileButton"
                style={style_state1_elIconButton22783}
                onClick={this.handleXButtonClick}
              />
            </div>

            <div className={SPRStyles.state1_elSave}>
              <Button
                className="actionFont saveProfileButton"
                style={style_state1_elSave}
                color="accent"
                onClick={this.onClick_state1_elSave}
              >
                Save
              </Button>
            </div>

            <div className={SPRStyles.flowRow}>{state1_elSaveSuccessful}</div>

            <div className={SPRStyles.state1_elActive853462}>
              <Checkbox
                className="baseFont soundProfileActiveCheckbox"
                style={style_state1_elActive853462}
                label={"Active"}
                onChange={this.checkboxChanged_active}
                checked={
                  checked_active === "true" ||
                  checked_active === true ||
                  "" + checked_active === "1"
                }
              />
            </div>
          </div>
          {/* If you add a new section of rule config to this page sure to update the user documentation linked below. 
          
          LINK discord-dungeon-user-docs/src/content/basic-usage/01-overriding.mdx#sound-profile-sections-docs
          
          */}
          <div
            className={`
              ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elAttackRules}
            `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"attack_rules"}
                ruleTypeName={"Attacks"}
                ruleType={"string"}
                ruleCaseType={"any"}
                ruleTypeExample="Longbow"
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"spell_rules"}
                ruleTypeName={"Spells"}
                ruleType={"string"}
                ruleCaseType={"any"}
                ruleTypeExample="Fire Bolt"
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
              ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
            `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"attack_role_rules"}
                ruleTypeName={"Attack Rolls"}
                ruleType={"numeric"}
                ruleCaseType={"any"}
                ruleTypeExample="20"
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"ability_check_roll_rules"}
                ruleTypeName={"Ability Check Rolls"}
                ruleType={"numeric"}
                ruleCaseType={"any"}
                ruleTypeExample="20"
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"ability_check_rules"}
                ruleTypeName={"Ability Check"}
                ruleType={"suggestion"}
                ruleCaseType={"lower"}
                ruleTypeExample="stealth, perception, etc."
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"saving_throw_roll_rules"}
                ruleTypeName={"Saving Throw Rolls"}
                ruleType={"numeric"}
                ruleCaseType={"any"}
                ruleTypeExample="20"
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"saving_throw_rules"}
                ruleTypeName={"Saving Throw"}
                ruleType={"suggestion"}
                ruleCaseType={"lower"}
                ruleTypeExample="strength, dexterity, etc."
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"initiative_rules"}
                ruleTypeName={"Initiative"}
                ruleType={"dropdown"}
                ruleCaseType={"any"}
                ruleTypeExample="begin/join"
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
          <div
            className={`
            ${SPRStyles.hasNestedComps} ${SPRStyles.state1_elSpellRules}
          `}
          >
            <div>
              <SoundProfileRuleConfig
                soundProfileConfig={this.soundProfileConfig}
                ruleId={"custom_sound_rules"}
                ruleTypeName={"Custom Sounds"}
                ruleType={"string"}
                ruleCaseType={"lower"}
                ruleTypeExample="dragonattack"
                appActions={this.props.appActions}
                deviceInfo={this.props.deviceInfo}
                locStrings={this.props.locStrings}
                soundsProvider={this.props.soundsProvider}
                foldersProvider={this.props.foldersProvider}
              />
            </div>
          </div>
          <div className={SPRStyles.state1_elSpacer2}>
            <div />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return withTrace(
      SpanName.SOUND_PROFILE_ROW_RENDER,
      () => {
        let page = null;
        switch (parseInt(this.state.visualStateIndexOverride, 10)) {
          default:
          case 0:
            page = this.renderState0();
            break;
          case 1:
            page = this.renderState1();
            break;
        }
        return (
          <TracingErrorBoundary name={ErrorBoundaryName.SOUND_PROFILE_ROW}>
            {page}
          </TracingErrorBoundary>
        );
      },
      {
        "soundProfile.name": this.props.soundProfile.name,
        active: this.state.active,
        visualStateIndexOverride: this.state.visualStateIndexOverride,
      }
    );
  }
}

SoundProfileRow.contextType = CurrentUserContext;

export default SoundProfileRow;

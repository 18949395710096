import React from "react";
import { isLoggedIn, alertErrorHelper, redirectOn401 } from "./util";

export const CurrentUserContext = React.createContext({
  user: {},
  updateStorageLimit: () => {},
});

export class AdminProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guild_name: "",
      usersByID: {},
      guildsByID: {},
      updateStorageLimit: this.updateStorageLimit,
      newGuild: this.newGuild,
      createGuild: this.createGuild,
      updateGuild: this.updateGuild,
      deleteGuild: this.deleteGuild,
    };
  }

  userUrl = "/api/v1/admin/users";

  loadAllUsers = () => {
    fetch(this.userUrl)
      .then((resp) =>
        redirectOn401(resp, (response) => {
          if (response.status === 401) {
            var list = [];
            return list;
          }
          if (response.status >= 400) {
            alertErrorHelper("Loading users", response);
            throw new Error("Server error: " + response.status);
          }
          const users = response.json();
          return users;
        })
      )
      .then((Users) => {
        const UsersById = Object.fromEntries(
          Users.map((user) => [user.id, user])
        );

        this.setState({ usersByID: UsersById });
      });
  };

  updateStorageLimit = (updatedUser) => {
    const opts = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedUser),
    };
    fetch(this.userUrl + `/${updatedUser.id}`, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper("Updating Current user", resp);
            new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((updatedUser) => {
        const updatedUsersByID = {
          ...this.state.usersByID,
          [updatedUser.id]: updatedUser,
        };
        this.setState({ usersByID: updatedUsersByID });
      });
  };

  guildUrl = "/api/v1/admin/guilds";

  loadAllGuilds = () => {
    fetch(this.guildUrl)
      .then((resp) =>
        redirectOn401(resp, (response) => {
          if (response.status === 401) {
            var list = [];
            return list;
          } else if (response.status >= 400) {
            alertErrorHelper("Loading users", response);
            throw new Error("Server error: " + response.status);
          }
          const guilds = response.json();
          return guilds;
        })
      )
      .then((guilds) => {
        const GuildsById = Object.fromEntries(
          guilds.map((guild) => [guild.id, guild])
        );

        this.setState({ guildsByID: GuildsById });
      });
  };

  createGuild = (newGuild) => {
    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newGuild),
    };
    fetch(this.guildUrl, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper(`Creating: ${newGuild.discord_guild_id}`, resp);
            throw new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((newGuild) => {
        const updatedCharacters = {
          ...this.state.guildsByID,
          [newGuild.id]: newGuild,
        };

        this.setState({ guildsByID: updatedCharacters });
      });
  };

  deleteGuild = (guild) => {
    const opts = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    fetch(this.guildUrl + `/${guild.id}`, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper(`Deleting: ${guild.discord_guild_id}`, resp);
            throw new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((deletedGuild) => {
        const { [deletedGuild.id]: _, ...remainingGuilds } =
          this.state.guildsByID;
        this.setState({ guildsByID: remainingGuilds });
      });
  };

  updateGuild = (updatedGuild) => {
    const opts = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedGuild),
    };
    fetch(this.guildUrl + `/${updatedGuild.id}`, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper(`Updating: ${updatedGuild.id}`, resp);
            throw new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((updatedGuild) => {
        const updatedGuilds = {
          ...this.state.guildsByID,
          [updatedGuild.id]: updatedGuild,
        };
        this.setState({ guildsByID: updatedGuilds });
      });
  };

  componentDidMount = () => {
    if (!isLoggedIn()) {
      return;
    }
    this.loadAllUsers();
    this.loadAllGuilds();
  };

  render() {
    return this.props.render(this.state);
  }
}

import React from "react";
import { isLoggedIn, alertErrorHelper, redirectOn401 } from "./util";

export class CharacterProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      characters: {},
      updateCharacter: this.updateCharacter,
      createCharacter: this.createCharacter,
      deleteCharacter: this.deleteCharacter,
    };
  }

  url = "/api/v1/my-characters";

  createCharacter = (newCharacter) => {
    const opts = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newCharacter),
    };
    fetch(this.url, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper(`Creating: ${newCharacter.character_name}`, resp);
            throw new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((newCharacter) => {
        const updatedCharacters = {
          ...this.state.characters,
          [newCharacter.id]: newCharacter,
        };

        this.setState({ characters: updatedCharacters });
      });
  };

  deleteCharacter = (character) => {
    const opts = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    fetch(this.url + `/${character.id}`, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper(`Deleting: ${character.character_name}`, resp);
            throw new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((deletedCharacter) => {
        const { [deletedCharacter.id]: _, ...remainingCharacters } =
          this.state.characters;
        this.setState({ characters: remainingCharacters });
      });
  };

  updateCharacter = (updatedCharacter) => {
    const opts = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedCharacter),
    };
    fetch(this.url + `/${updatedCharacter.id}`, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper(
              `Updating: ${updatedCharacter.character_name}`,
              resp
            );
            throw new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((newCharacter) => {
        const updatedCharacters = {
          ...this.state.characters,
          [newCharacter.id]: newCharacter,
        };
        this.setState({ characters: updatedCharacters });
      });
  };

  loadCharacters = () => {
    fetch(this.url)
      .then((resp) =>
        redirectOn401(resp, (response) => {
          if (response.status >= 400) {
            alertErrorHelper(`Loading characters`, response);
            throw new Error("Server error: " + response.status);
          }
          const characters = response.json();
          return characters;
        })
      )
      .then((characters) => {
        const charactersById = Object.fromEntries(
          characters.map((character) => [character.id, character])
        );

        this.setState({ characters: charactersById });
      });
  };

  componentDidMount = () => {
    if (!isLoggedIn()) {
      return;
    }
    this.loadCharacters();
  };

  render() {
    return this.props.render(this.state);
  }
}

import React, { Component } from "react";
import FolderTree from "./FolderTree";
import SoundRow from "./SoundRow";
import SoundFooter from "./SoundFooter";
import SoundUploadModal from "./ModalSoundUpload";
import ModalSoundOptions from "./ModalSoundOptions";
import "react-table/react-table.css";
import { CurrentUserContext } from "./server-state/CurrentUserContext";
import * as S2SStyles from "./styles/Sounds2Screen.module.css";
import { SortSounds } from "./server-state/util";

import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Layout, Button as AntButton, Tooltip } from "antd";
import {
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import {
  Sound,
  State as SoundsProviderState,
} from "./server-state/SoundsProvider";
import { State as FolderProviderState } from "./server-state/FoldersProvider";
import { TracingErrorBoundary } from "./tracing/TracingErrorBoundary";
import { ErrorBoundaryName, SpanName } from "./tracing/SpanNames";
import { withTrace } from "./tracing/tracing";

export interface Props {
  appActions: unknown;
  locStrings: unknown;
  soundsProvider: SoundsProviderState;
  foldersProvider: FolderProviderState;
}

export interface State {
  isSoundUploadModalVisible: boolean;
  isSoundModalVisible: boolean;
  isRenamingSound: boolean;
  selectedFolder: string;
  selectedSound: Sound | null;
  isAutoPlay: boolean;
  isSiderCollapsed: boolean;
  soundIndex: number | null;
  windowSize: number;
  collapsedWidth: number;
  siderOpenWidth: number;
}

class SoundManager extends Component<Props, State> {
  loadedSounds: Array<Sound>;
  usedStorageKb: number;
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      isSoundUploadModalVisible: false,
      isSoundModalVisible: false,
      isRenamingSound: false,
      selectedFolder: "",
      selectedSound: null,
      isSiderCollapsed:
        !localStorage.getItem("isSiderCollapsed") == null
          ? false
          : localStorage.getItem("isSiderCollapsed") === "true",
      isAutoPlay:
        !localStorage.getItem("isAutoPlay") == null
          ? true
          : localStorage.getItem("isAutoPlay") === "true",
      soundIndex: null,
      windowSize: window.innerWidth,
      collapsedWidth: window.innerWidth > 400 ? 150 : 100,
      siderOpenWidth: window.innerWidth > 500 ? 400 : 200,
    };
    this.loadedSounds = [];
    this.usedStorageKb = 0;
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    if (this.state.windowSize !== window.innerWidth) {
      this.setState({
        windowSize: window.innerWidth,
        collapsedWidth: window.innerWidth > 400 ? 150 : 100,
        siderOpenWidth: window.innerWidth > 500 ? 400 : 200,
      });
    }
  }

  setSoundModalVisible = (soundModalOpen: boolean, renamingSound: boolean) => {
    if (soundModalOpen) {
      this.setState({
        isSoundModalVisible: true,
        isRenamingSound: renamingSound,
      });
    } else {
      this.setState({
        isSoundModalVisible: false,
        isRenamingSound: false,
      });
    }
  };

  handleSoundSelect = (index: number) => {
    this.setState({
      selectedSound: this.loadedSounds[index],
      soundIndex: index,
    });
  };

  handleNextButton = (index: number | null) => {
    if (index !== null && this.loadedSounds.length > index + 1) {
      this.handleSoundSelect(index + 1);
    }
  };

  handleBackButton = (index: number | null) => {
    if (index !== null && index - 1 >= 0) {
      this.handleSoundSelect(index - 1);
    }
  };

  toggleUserModal = () => {
    this.setState({
      isSoundUploadModalVisible: !this.state.isSoundUploadModalVisible,
    });
  };

  toggleAutoPlay = () => {
    localStorage.setItem("isAutoPlay", String(!this.state.isAutoPlay));
    this.setState({ isAutoPlay: !this.state.isAutoPlay });
  };

  toggleSiderCollapse = () => {
    localStorage.setItem(
      "isSiderCollapsed",
      String(!this.state.isSiderCollapsed)
    );
    this.setState({ isSiderCollapsed: !this.state.isSiderCollapsed });
  };

  handleFolderSelect = (event: any) => {
    this.setState({ selectedFolder: event[0] });
  };

  buildFolderTree = (folder: any): any => {
    return {
      key: folder.id,
      title: folder.name,
      children: this.props.foldersProvider.foldersByParentId[folder.id]?.map(
        this.buildFolderTree
      ),
    };
  };

  loadSounds = () => {
    let folderSounds =
      this.props.soundsProvider.soundsByFolderId[this.state.selectedFolder];
    if (!this.state.selectedFolder) {
      return SortSounds(
        Object.values(this.props.soundsProvider.userSounds)
      ).concat(Object.values(this.props.soundsProvider.defaultSounds));
    } else if (folderSounds !== undefined) {
      return SortSounds(folderSounds);
    } else {
      return [];
    }
  };

  getStorageUsage = () => {
    this.usedStorageKb = +(
      Object.values(this.props.soundsProvider.userSounds).reduce(
        (partialSum: number, sound: Sound) => partialSum + sound.size_in_bytes,
        0
      ) / 1000
    ).toFixed(2);

    let storageUsedPrecentage = +(
      (Object.values(this.props.soundsProvider.userSounds).reduce(
        (partialSum: number, sound: Sound) => partialSum + sound.size_in_bytes,
        0
      ) /
        parseInt(this.context.user.storage_limit_bytes)) *
      100
    ).toFixed(2);
    return storageUsedPrecentage;
  };

  private buildSoundsTable() {
    const { Content } = Layout;
    return (
      <Layout>
        <Content
          className={`site-layout-background ${S2SStyles.AntdContentBlock}`}
          style={{
            paddingTop: 12,
            paddingBottom: 12,
            paddingRight: 24,
            paddingLeft: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="sounds table">
              <TableHead>{this.buildSoundsTableHeader()}</TableHead>
              <TableBody>{this.buildSoundsTableBody()}</TableBody>
            </Table>
            <ModalSoundOptions
              soundsProvider={this.props.soundsProvider}
              foldersProvider={this.props.foldersProvider}
              selectedSound={this.state.selectedSound}
              setSoundModalVisible={this.setSoundModalVisible}
              isModalSoundOptionsVisible={this.state.isSoundModalVisible}
              isRenamingSound={this.state.isRenamingSound}
            />
          </TableContainer>
        </Content>
      </Layout>
    );
  }

  private buildHeader(storageUsedPrecentage: number) {
    const { Header } = Layout;
    return (
      <Header className={`${S2SStyles.SecondaryHeader}`}>
        <Toolbar
          disableGutters
          className={`${S2SStyles.SecondaryHeaderToolBar}`}
        >
          {this.buildOpenSoundUploadModalButton()}
          {this.buildUsedStorageBar(storageUsedPrecentage)}
        </Toolbar>
        <SoundUploadModal
          soundsProvider={this.props.soundsProvider}
          userVisible={this.state.isSoundUploadModalVisible}
          toggleUserModal={this.toggleUserModal}
          selectedFolder={this.state.selectedFolder}
        />
      </Header>
    );
  }

  private buildSoundFooter() {
    return (
      <SoundFooter
        soundData={this.state.selectedSound}
        isAutoPlay={this.state.isAutoPlay}
        soundIndex={this.state.soundIndex}
        handleNextButton={this.handleNextButton}
        handleBackButton={this.handleBackButton}
        toggleAutoPlay={this.toggleAutoPlay}
        windowSize={this.state.windowSize}
      />
    );
  }

  private buildSoundsTableBody(): React.ReactNode {
    return this.loadedSounds.map((sound: Sound, index: number) => {
      let tablesoundrow = (
        <SoundRow
          soundsProvider={this.props.soundsProvider}
          sound={sound}
          soundIndex={index}
          selectedSoundIndex={this.state.soundIndex}
          handleSoundSelect={this.handleSoundSelect}
          setSoundModalVisible={this.setSoundModalVisible}
          windowSize={this.state.windowSize}
        />
      );
      return tablesoundrow;
    });
  }

  private buildSoundsTableHeader() {
    return (
      <TableRow>
        <TableCell className={S2SStyles.MUITableStyles}>Name</TableCell>
        {this.state.windowSize > 520 && (
          <TableCell className={S2SStyles.MUITableStyles} align="right">
            Length (Sec)
          </TableCell>
        )}
        <TableCell
          className={S2SStyles.MUITableStyles}
          align="right"
        ></TableCell>
      </TableRow>
    );
  }

  private buildUsedStorageBar(storageUsedPrecentage: number) {
    return this.state.windowSize > 520 ? (
      <Box sx={{ flexGrow: 0, ml: 0, marginLeft: "20px" }}>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            sx={{ fontSize: "1.5rem" }}
            variant="body2"
            color="white"
          >{`${this.usedStorageKb}/${
            this.context.user.storage_limit_bytes / 1000
          } Kbs used`}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LinearProgress
            sx={{
              width: "120px",
              height: "1.5rem",
              marginRight: "5px",
              marginBottom: "3px",
            }}
            variant="determinate"
            value={storageUsedPrecentage}
          />
          <Typography
            sx={{ fontSize: "1.5rem" }}
            variant="body2"
            color="white"
          >{`${storageUsedPrecentage}%`}</Typography>
        </Box>
      </Box>
    ) : (
      <Box sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
        <CircularProgress variant="determinate" value={storageUsedPrecentage} />
        <Typography
          sx={{ fontSize: "1.5rem", paddingLeft: "10px" }}
          variant="caption"
          component="div"
          color="white"
        >{`${Math.round(storageUsedPrecentage)}%`}</Typography>
      </Box>
    );
  }

  private buildOpenSoundUploadModalButton() {
    return (
      <Box sx={{ flexGrow: 0, ml: 0 }}>
        <Tooltip
          title="Sound Upload"
          placement="bottom"
          className="NewSoundButton"
        >
          <AntButton
            className={` ${S2SStyles.NewSoundButton}`}
            shape="circle"
            color="primary"
            onClick={this.toggleUserModal}
            icon={<PlusOutlined />}
          ></AntButton>
        </Tooltip>
      </Box>
    );
  }

  private buildSoundFolderSidebar(treeData: any[]) {
    const { Sider } = Layout;
    return (
      <Sider
        collapsedWidth={this.state.collapsedWidth}
        width={this.state.siderOpenWidth}
        className={`${S2SStyles.SiderBackground}`}
        collapsible
        collapsed={this.state.isSiderCollapsed}
        trigger={null}
      >
        <button
          className={`${S2SStyles.SiderToggle}`}
          onClick={() => this.toggleSiderCollapse()}
        >
          <span>
            {this.state.isSiderCollapsed ? (
              <RightOutlined
                style={{ fontSize: "20px", color: "rgb(25, 118, 210)" }}
              />
            ) : (
              <LeftOutlined
                style={{ fontSize: "20px", color: "rgb(25, 118, 210)" }}
              />
            )}
          </span>
        </button>
        {this.state.isSiderCollapsed ? (
          <div className={S2SStyles.SiderTitle}>
            <FolderOutlined style={{ fontSize: "32px" }} />
          </div>
        ) : (
          <h3 className={S2SStyles.SiderTitle}>Sound Folders</h3>
        )}
        {/* <Tree
          className={S2SStyles.LeftMenuScroll}
          defaultExpandAll
          switcherIcon={<RightCircleOutlined />}
          treeData={treeData}
          onSelect={this.handleFolderSelect}
        /> */}
        <FolderTree
          foldersProvider={this.props.foldersProvider}
          selectedFolder={this.state.selectedFolder}
          handleFolderSelect={this.handleFolderSelect}
        />
      </Sider>
    );
  }

  private getTreeData() {
    return this.props.foldersProvider.foldersByParentId["root"].map((item) => {
      return this.buildFolderTree(item);
    });
  }

  render() {
    let treeData = this.getTreeData();
    this.loadedSounds = this.loadSounds();
    const storageUsedPrecentage = this.getStorageUsage();

    return withTrace(SpanName.SOUND_MANAGER_RENDER, () => {
      return (
        <TracingErrorBoundary name={ErrorBoundaryName.SOUND_MANAGER}>
          <Layout className={`mySoundsSection  ${S2SStyles.AntDPagePadding}`}>
            <Layout>
              {this.buildSoundFolderSidebar(treeData)}
              <Layout>
                {this.buildHeader(storageUsedPrecentage)}
                {this.buildSoundsTable()}
              </Layout>
            </Layout>
            {this.buildSoundFooter()}
          </Layout>
        </TracingErrorBoundary>
      );
    });
  }
}

SoundManager.contextType = CurrentUserContext;

export default SoundManager;

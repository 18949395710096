import React, { Component } from "react";
import { DeleteOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Modal, Input, TreeSelect } from "antd";
import {
  Sound,
  State as SoundsProviderState,
} from "./server-state/SoundsProvider";
import { State as FoldersProviderState } from "./server-state/FoldersProvider";

export interface Props {
  soundsProvider: SoundsProviderState;
  foldersProvider: FoldersProviderState;
  selectedSound: Sound | null;
  isModalSoundOptionsVisible: boolean;
  isRenamingSound: boolean;
  setSoundModalVisible: (
    soundModalOpen: boolean,
    renamingSound: boolean
  ) => void;
}

export interface State {
  updateSoundName: string | undefined;
}

class ModalSoundOptions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      updateSoundName: undefined,
    };
  }

  handleUpdateSoundName = (updateSoundName: string | undefined) => {
    if (!updateSoundName || updateSoundName === "") {
      alert("Sound name required");
    } else if (this.props.selectedSound !== null) {
      let soundToUpdate = this.props.selectedSound;
      soundToUpdate.name = updateSoundName;
      this.props.soundsProvider.updateUserSound(soundToUpdate, undefined);
    }
    this.props.setSoundModalVisible(false, false);
  };

  handleUpdateSoundFolder = (folderid: string | undefined) => {
    if (!folderid || folderid === "") {
      alert("Folder required");
    } else if (this.props.selectedSound !== null) {
      let soundToUpdate = this.props.selectedSound;
      let oldFolderId = soundToUpdate.folder_id;
      soundToUpdate.folder_id = folderid;
      this.props.soundsProvider.updateUserSound(soundToUpdate, oldFolderId);
    }
    this.props.setSoundModalVisible(false, false);
  };

  textInputChanged_Folder_Name = (event: any) => {
    this.setState({ updateSoundName: event.target.value });
  };

  pickerFolderSelect = (event: any) => {
    this.handleUpdateSoundFolder(event);
  };

  onClickDeleteButton = () => {
    let shouldDelete = window.confirm(
      `Are you sure you want to delete the ${this.props.selectedSound?.name} sound?`
    );

    if (shouldDelete && this.props.selectedSound != null) {
      // Remove sound from user sounds
      this.props.setSoundModalVisible(false, false);
      this.props.soundsProvider.deleteUserSound(this.props.selectedSound);
    }
  };

  private buildModalHeader() {
    return this.props.isRenamingSound === false ? (
      <span style={{ display: "block" }}>
        {this.props.selectedSound?.is_editor && (
          <Button
            className="RenameSound"
            onClick={() => {
              this.props.setSoundModalVisible(true, true);
              this.setState({
                updateSoundName: this.props.selectedSound?.name,
              });
            }}
          >
            <EditOutlined />
          </Button>
        )}
        <h4
          style={{
            marginTop: 5,
            marginInline: 10,
            marginBottom: 0,
            display: "inline",
          }}
        >
          {this.props.selectedSound?.name}
        </h4>
      </span>
    ) : (
      <Input.Group compact>
        <Button
          className="SubmitName"
          onClick={() => {
            this.handleUpdateSoundName(this.state.updateSoundName);
          }}
        >
          <CheckOutlined />
        </Button>
        <Input
          className="UpdateNameInput"
          style={{ width: "calc(100% - 75px)" }}
          defaultValue={this.props.selectedSound?.name}
          onChange={this.textInputChanged_Folder_Name}
        ></Input>
      </Input.Group>
    );
  }

  getTreeData = () => {
    return this.props.foldersProvider.foldersByParentId["root"]
      .filter((x) => !x.is_global)
      .map((item) => {
        return this.buildFolderTree(item);
      });
  };

  buildFolderTree = (folder: any): any => {
    return {
      value: folder.id,
      title: folder.name,
      children: this.props.foldersProvider.foldersByParentId[folder.id]?.map(
        this.buildFolderTree
      ),
    };
  };

  render() {
    return (
      <Modal
        title={[this.buildModalHeader()]}
        visible={this.props.isModalSoundOptionsVisible}
        onCancel={() => this.props.setSoundModalVisible(false, false)}
        footer={[
          <Button
            key="back"
            onClick={() => this.props.setSoundModalVisible(false, false)}
          >
            Close
          </Button>,
        ]}
      >
        {this.props.selectedSound?.is_editor &&
          this.props.isModalSoundOptionsVisible && (
            <TreeSelect
              className="MoveSoundDropDown"
              showSearch
              treeDefaultExpandAll
              treeData={this.getTreeData()}
              style={{ width: "100%" }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              value={
                this.props.foldersProvider.foldersById[
                  this.props.selectedSound.folder_id
                ].name
              }
              onChange={this.pickerFolderSelect}
            ></TreeSelect>
          )}
        <div>
          Size (kB):{" "}
          {(
            (this.props.selectedSound?.size_in_bytes
              ? this.props.selectedSound?.size_in_bytes
              : 1024) / 1024
          ).toFixed(2)}
        </div>
        <div>
          Length (Sec): {this.props.selectedSound?.length_in_seconds.toFixed(2)}
        </div>
        {this.props.selectedSound?.is_editor && (
          <Button
            style={{
              marginTop: 10,
            }}
            className="deleteSoundButton"
            onClick={this.onClickDeleteButton}
          >
            <DeleteOutlined />
            Delete
          </Button>
        )}
      </Modal>
    );
  }
}
export default ModalSoundOptions;

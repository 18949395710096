import React, { Component } from "react";

export default class LoginFailedScreen extends Component {
  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (
      this.props.transitionId &&
      this.props.transitionId.length > 0 &&
      this.props.atTopOfScreenStack &&
      this.props.transitionForward
    ) {
      baseStyle.animation = "0.25s ease-in-out " + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = "100vh";
      layoutFlowStyle.overflow = "hidden";
    }

    return (
      <div className="AppScreen LoginFailedScreen" style={baseStyle}></div>
    );
  }
}

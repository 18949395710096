import React, { Component } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { TableCell, TableRow } from "@mui/material";
import * as S2SStyles from "./styles/Sounds2Screen.module.css";
import {
  Sound,
  State as SoundsProviderState,
} from "./server-state/SoundsProvider";

export interface Props {
  soundsProvider: SoundsProviderState;
  sound: Sound;
  soundIndex: number;
  selectedSoundIndex: number | null;
  handleSoundSelect: (number: number) => void;
  setSoundModalVisible: (
    soundModalOpen: boolean,
    renamingSound: boolean
  ) => void;
  windowSize: number;
}

export interface State {}

class SoundRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  handleSoundChange = () => {
    this.props.handleSoundSelect(this.props.soundIndex);
  };

  renderTableRow() {
    const SoundId = this.props.sound.id;
    const SoundName = this.props.sound.name;
    const SoundLength = this.props.sound.length_in_seconds;

    const HighLightedRow = {
      backgroundImage: "linear-gradient(#99ccff,#2196f3)",
    };

    let soundOptionsButton = <></>;
    if (this.props.sound.is_editor) {
      soundOptionsButton = (
        <Button
          shape="circle"
          onClick={() => this.props.setSoundModalVisible(true, false)}
          className="SoundOptionsButton"
        >
          <EllipsisOutlined />
        </Button>
      );
    }

    const className = this.props.sound.is_editor
      ? "SoundRow"
      : "ImmutableSoundRow";

    return (
      <TableRow
        className={className}
        hover
        key={SoundId}
        sx={{ border: 0 }}
        onClick={this.handleSoundChange}
        id={SoundId}
        style={
          this.props.soundIndex === this.props.selectedSoundIndex
            ? HighLightedRow
            : {}
        }
      >
        <TableCell className={S2SStyles.MUITableStyles} sx={{ paddingY: 1 }}>
          {SoundName}
        </TableCell>
        {this.props.windowSize > 520 && (
          <TableCell
            className={S2SStyles.MUITableStyles}
            sx={{ paddingY: 1 }}
            align="right"
          >
            {SoundLength.toFixed(2)}
          </TableCell>
        )}
        <TableCell
          className={S2SStyles.MUITableStyles}
          sx={{ paddingY: 1 }}
          align="right"
        >
          {soundOptionsButton}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    return this.renderTableRow();
  }
}

export default SoundRow;

import React, { Component } from "react";
import { Tree } from "antd";
import type { TreeProps, EventDataNode } from "antd/es/tree";
import {
  Folder,
  State as FolderProviderState,
} from "./server-state/FoldersProvider";
import Popup from "./ModalFolderTree";
import * as FolderTreeStyles from "./styles/FolderTree.module.css";

export interface Props {
  foldersProvider: FolderProviderState;
  selectedFolder: string;
  handleFolderSelect: (event: any) => void;
}

export interface State {
  isRenamingFolder: boolean;
  isContextMenuVisible: boolean;
  popup: {
    folder: Folder | null;
    canEditNameAndDelete: boolean;
    canAddFolder: boolean;
    x: number;
    y: number;
  };
}

class FolderTree extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isRenamingFolder: false,
      isContextMenuVisible: false,
      popup: {
        folder: null,
        canEditNameAndDelete: false,
        canAddFolder: false,
        x: 0,
        y: 0,
      },
    };
  }

  handleNewFolder = (newFolderName: string | undefined) => {
    if (!newFolderName || newFolderName === "") {
      alert("Folder name is required");
      return;
    }
    if (this.state.popup.folder?.id) {
      let newFolder = {
        name: newFolderName,
        parent_id: this.state.popup.folder?.id,
      };
      this.props.foldersProvider.createUserFolder(newFolder);
      this.setState({ isRenamingFolder: false, isContextMenuVisible: false });
    }
  };

  handleUpdateFolderName = (updateFolderName: string | undefined) => {
    if (!updateFolderName || updateFolderName === "") {
      alert("Folder name is required");
      return;
    } else if (updateFolderName === this.state.popup.folder?.name) {
      this.setState({ isRenamingFolder: false, isContextMenuVisible: false });
      return;
    }
    if (this.state.popup.folder?.id) {
      let folderToUpdate = this.state.popup.folder;
      folderToUpdate.name = updateFolderName;
      this.props.foldersProvider.updateUserFolder(
        folderToUpdate,
        folderToUpdate.parent_id
      );
      this.setState({ isRenamingFolder: false, isContextMenuVisible: false });
    }
  };

  onClickDeleteButton = () => {
    let shouldDelete = window.confirm(
      `Are you sure you want to delete ${this.state.popup.folder?.name} and all of its sub-folders?`
    );

    if (shouldDelete && this.state.popup.folder?.id) {
      // Remove sound from user sounds
      this.props.foldersProvider.deleteUserFolder(
        this.props.foldersProvider.foldersById[this.state.popup.folder?.id]
      );
      this.setState({ isRenamingFolder: false, isContextMenuVisible: false });
      this.props.handleFolderSelect("");
    } else if (shouldDelete === true) {
      window.confirm("Error has occured");
    }
  };

  getTreeData = () => {
    const sorted = [
      ...this.props.foldersProvider.foldersByParentId["root"],
    ].sort((a, b) => (a.name > b.name ? 1 : -1));
    return sorted.map((item) => {
      return this.buildFolderTree(item);
    });
  };

  buildFolderTree = (folder: any): any => {
    return {
      key: folder.id,
      title: folder.name,
      children: this.props.foldersProvider.foldersByParentId[folder.id]?.map(
        this.buildFolderTree
      ),
    };
  };

  onDrop: TreeProps["onDrop"] = (info) => {
    console.log(info);
    let folderToUpdate =
      this.props.foldersProvider.foldersById[info.dragNode.key.toString()];
    const originalParentId = folderToUpdate.parent_id;

    if (info.dropToGap === false) {
      folderToUpdate.parent_id = info.node.key.toString();
    } else if (info.dropToGap) {
      folderToUpdate.parent_id =
        this.props.foldersProvider.foldersById[info.node.key].parent_id;
    }

    if (originalParentId !== folderToUpdate.parent_id) {
      this.props.foldersProvider.updateUserFolder(
        folderToUpdate,
        originalParentId
      );
    }
  };

  isDraggable = (node: any) => {
    return node.title !== "default";
  };

  setContextMenuVisible = (contextMenuOpen: boolean, rename: boolean) => {
    if (contextMenuOpen) {
      this.setState({
        isContextMenuVisible: true,
        isRenamingFolder: rename,
      });
    } else {
      this.setState({
        isContextMenuVisible: false,
        isRenamingFolder: false,
      });
    }
  };

  folderContextClick = (info: {
    event: React.MouseEvent<Element, MouseEvent>;
    node: EventDataNode;
  }) => {
    this.setContextMenuVisible(true, false);
    let tempFolder = this.props.foldersProvider.foldersById[info.node.key];
    this.setState({
      popup: {
        folder: tempFolder,
        canEditNameAndDelete:
          tempFolder.is_editor &&
          tempFolder.parent_id != null &&
          !tempFolder.is_global,
        canAddFolder: tempFolder.is_editor,
        x: info.event.clientX,
        y: info.event.clientY,
      },
      isContextMenuVisible: true,
    });
    console.log(
      "noded.key parent: " +
        this.props.foldersProvider.foldersById[info.node.key].parent_id
    );
  };

  render() {
    return (
      <div className={`${FolderTreeStyles.overFlow}`}>
        <Tree
          className="draggable-tree"
          draggable={this.isDraggable}
          blockNode
          onDrop={this.onDrop}
          treeData={this.getTreeData()}
          icon={true}
          onRightClick={this.folderContextClick}
          onSelect={this.props.handleFolderSelect}
        />
        {this.state.isContextMenuVisible && (
          <Popup
            popup={this.state.popup}
            isContextMenuVisible={this.state.isContextMenuVisible}
            isRenamingFolder={this.state.isRenamingFolder}
            onClickDeleteButton={this.onClickDeleteButton}
            handleUpdateFolderName={this.handleUpdateFolderName}
            handleNewFolder={this.handleNewFolder}
            toggleContextMenu={this.setContextMenuVisible}
          />
        )}
      </div>
    );
  }
}

export default FolderTree;
